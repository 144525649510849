import { useState, useEffect } from 'react';

const useCeopEquipmentStatus = (equipmentId) => {
  const [equipment, setEquipment] = useState(null);
  const token = sessionStorage.getItem('accessToken');

  useEffect(() => {
    const fetchEquipment = async () => {
      try {
        const response = await fetch(`https://serverwinglet.winglet.app/api/ceop_consultequipments/${equipmentId}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`, // Include the token in the Authorization header
          },
        });

        if (response.ok) {
          const data = await response.json();
          setEquipment(data.equipment); // Access the equipment property
        } else {
          console.error('Failed to fetch equipment data');
        }
      } catch (error) {
        console.error('Error fetching equipment data:', error);
      }
    };

    if (token && equipmentId) {
      fetchEquipment();
    }
    const ws = new WebSocket('wss://serverwinglet.winglet.app');

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'UPDATE_EQUIPMENT') {
        fetchEquipment(); // Handle the real-time update
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };

    return () => {
      ws.close();
    };
  }, [token, equipmentId]);

  return equipment;
};

export default useCeopEquipmentStatus;