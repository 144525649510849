import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  IconButton,
  Divider,
  Alert, 
  Snackbar,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import {
  QrCodeScanner as QrCodeScannerIcon,
  AddToHomeScreenOutlined as DeliveryIcon,
  InstallMobileOutlined as ReceiveIcon,
  KeyboardHideOutlined as KeyboardIcon,
  PhonelinkEraseOutlined as BrokenIcon,
  AddPhotoAlternate as AddPhotoIcon,
  Delete as DeleteIcon,
  AnalyticsOutlined as ArrowForwardIcon,
} from "@mui/icons-material";
import useAuth from "../../../hooks/useAuth";
import { UserContext } from "../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import DefaultLayout from "../../../components/DefaultLayout";
import useCeopEquipmentStatus from '../../../hooks/ceop/ceopEquipmentStatus';
import useEmployeeInfo from "../../../hooks/ceop/getEmployees";

const ScanEquipmentPage = () => {
  useAuth();

  const [firstFieldValue, setFirstFieldValue] = useState("");
  const [secondFieldValue, setSecondFieldValue] = useState("");
  const [isFirstFieldDisabled, setIsFirstFieldDisabled] = useState(false);
  const [isSecondFieldVisible, setIsSecondFieldVisible] = useState(false);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [equipmentInfo, setEquipmentInfo] = useState(null);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [addObservations, setAddObservations] = useState(false);
  const [observations, setObservations] = useState("");
  const [photos, setPhotos] = useState([]);

  const firstFieldRef = useRef(null);
  const secondFieldRef = useRef(null);

  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const fetchedEquipmentInfo = useCeopEquipmentStatus(firstFieldValue);
  const fetchedEmployeeInfo = useEmployeeInfo(secondFieldValue);

  useEffect(() => {
    if (firstFieldValue.trim() !== "") {
      setEquipmentInfo(fetchedEquipmentInfo);
    }
  }, [firstFieldValue, fetchedEquipmentInfo]);

  useEffect(() => {
    if (secondFieldValue.trim() !== "") {
      setEmployeeInfo(fetchedEmployeeInfo);
      if (fetchedEquipmentInfo?.currentStatus === 66 || fetchedEquipmentInfo?.currentStatus === 99 || fetchedEquipmentInfo?.currentStatus === 77) {
        setAlertOpen(true);
        setAlertMessage(
          fetchedEquipmentInfo.currentStatus === 66
            ? "O equipamento está marcado como quebrado e não pode ser utilizado."
            : fetchedEquipmentInfo.currentStatus === 99
            ? "O equipamento está inativo e não pode ser utilizado."
            : "Equipamento não existente"
        );
        setAlertSeverity("error");
        handleCancel();
      }
    }
  }, [secondFieldValue, fetchedEmployeeInfo, fetchedEquipmentInfo]);

  useEffect(() => {
    if (!isFirstFieldDisabled) {
      firstFieldRef.current?.focus();
    } else if (isSecondFieldVisible) {
      secondFieldRef.current?.focus();
    }
  }, [isFirstFieldDisabled, isSecondFieldVisible]);

  useEffect(() => {
    if (equipmentInfo && isSecondFieldVisible) {
      setFirstFieldValue(`${firstFieldValue} - ${equipmentInfo.type} ${equipmentInfo.manufacturer}`);
    }
  }, [equipmentInfo, isSecondFieldVisible]);

  const confirmOperation = useCallback(async () => {
    if (!equipmentInfo || !employeeInfo) {
      setAlertOpen(true);
      setAlertMessage("Dados incompletos. Certifique-se de que todas as informações estão preenchidas antes de confirmar.");
      setAlertSeverity("warning");
      return;
    }

    if (equipmentInfo.currentStatus === 66 || equipmentInfo.currentStatus === 99 || equipmentInfo.currentStatus === 77) {
      setAlertOpen(true);
      setAlertMessage(
        equipmentInfo.currentStatus === 66
          ? "O equipamento está marcado como quebrado e não pode ser utilizado."
          : equipmentInfo.currentStatus === 99
          ? "O equipamento está inativo e não pode ser utilizado."
          : "Equipamento não existente"
      );
      setAlertSeverity("error");
      return;
    }

    setIsSubmitting(true);
    setIsLoading(true);
    try {
      const response = await fetch("https://serverwinglet.winglet.app/api/update_equipment", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          id: equipmentInfo?.id,
          equipment: firstFieldValue,
          status:
            equipmentInfo?.currentStatus === 0
              ? 1
              : equipmentInfo?.currentStatus === 1
              ? addObservations
                ? 66
                : 0
              : equipmentInfo?.currentStatus,
          user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`,
          funcao: `${employeeInfo?.company_role}`,
          badge: secondFieldValue,
          employeeName: `${employeeInfo?.name}`,
          observations: observations,
          photos: photos,
        }),
        headers: {
          Authorization: `${sessionStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setAlertOpen(true);
        setAlertMessage(`Operação realizada com sucesso`);
        setAlertSeverity("success");
        setEquipmentInfo(null);
        setEmployeeInfo(null);
        handleCancel();
      } else {
        setAlertOpen(true);
        setAlertMessage("Ops! Algo deu errado. Tente novamente");
        setAlertSeverity("warning");
        handleCancel();
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertMessage(`Ops! Não foi possível completar a operação. Erro: ${error.message}`);
      setAlertSeverity("error");
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
      setEmployeeInfo(null);
      setEquipmentInfo(null);
    }
  }, [addObservations, employeeInfo, equipmentInfo, firstFieldValue, observations, photos, secondFieldValue, user]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleCancel();
      } else if (event.key === "Enter" && isSummaryVisible) {
        confirmOperation();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSummaryVisible, confirmOperation]);

  const handleCancel = () => {
    setFirstFieldValue("");
    setSecondFieldValue("");
    setIsFirstFieldDisabled(false);
    setIsSecondFieldVisible(false);
    setIsSummaryVisible(false);
    setIsCameraVisible(false);
    setAddObservations(false);
    setObservations("");
    setPhotos([]);
    setEquipmentInfo(null);
    setEmployeeInfo(null);
    firstFieldRef.current?.focus();
  };

  const handleFirstFieldKeyPress = (event) => {
    if (event.key === "Enter" && firstFieldValue.trim() !== "") {
      if (fetchedEquipmentInfo?.currentStatus === 77) {
        setAlertOpen(true);
        setAlertMessage("Equipamento não existente");
        setAlertSeverity("error");
        handleCancel();
        return;
      }
      setIsFirstFieldDisabled(true);
      setIsSecondFieldVisible(true);
    }
  };

  const handleSecondFieldKeyPress = (event) => {
    if (event.key === "Enter" && secondFieldValue.trim() !== "") {
      if (fetchedEquipmentInfo?.currentStatus === 66 || fetchedEquipmentInfo?.currentStatus === 99 || fetchedEquipmentInfo?.currentStatus === 77) {
        setAlertOpen(true);
        setAlertMessage(
          fetchedEquipmentInfo.currentStatus === 66
            ? "O equipamento está marcado como quebrado e não pode ser utilizado."
            : fetchedEquipmentInfo.currentStatus === 99
            ? "O equipamento está inativo e não pode ser utilizado."
            : "Equipamento não existente"
        );
        setAlertSeverity("error");
        handleCancel();
        return;
      }
      setIsSummaryVisible(true);
    }
  };

  const handleNextClick = () => {
    if (!isSecondFieldVisible) {
      if (fetchedEquipmentInfo?.currentStatus === 77) {
        setAlertOpen(true);
        setAlertMessage("Equipamento não existente");
        setAlertSeverity("error");
        handleCancel();
        return;
      }
      setIsFirstFieldDisabled(true);
      setIsSecondFieldVisible(true);
    } else if (!isSummaryVisible) {
      setIsSummaryVisible(true);
    }
  };

  const handlePhotoAdd = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Opções de compressão para reduzir a qualidade da foto
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
          initialQuality: 0.7,
        };

        // Comprimir a imagem antes de enviá-la
        const compressedFile = await imageCompression(file, options);

        // FormData para enviar a imagem comprimida
        const formData = new FormData();
        formData.append('photo', compressedFile);

        const response = await fetch('https://serverwinglet.winglet.app/api/upload_photo', {
          method: 'POST',
          credentials: 'include',
          body: formData,
          headers: {
            Authorization: `${sessionStorage.getItem('accessToken')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPhotos((prevPhotos) => [...prevPhotos, data.photoUrl]);
        } else {
          console.error('Failed to upload photo');
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    }
  };

  const handlePhotoDelete = (index) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  const renderPhotoUploadAreas = () => {
    const uploadAreas = [];
    for (let i = 0; i < photos.length; i++) {
      uploadAreas.push(
        <Box key={i} sx={{ mb: 2, position: 'relative', width: 100, height: 100, border: '2px dashed #E0E0E0', borderRadius: '8px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {photos[i] ? (
            <>
              <img
                src={photos[i]}
                alt={`photo-${i}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <IconButton
                sx={{ position: 'absolute', top: 5, right: 5, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                onClick={() => handlePhotoDelete(i)}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </>
          ) : null}
        </Box>
      );
    }

    uploadAreas.push(
      <Box key={photos.length} sx={{ mb: 2, position: 'relative', width: 100, height: 100, border: '2px dashed #E0E0E0', borderRadius: '8px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          variant="contained"
          component="label"
          sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}
        >
          <AddPhotoIcon sx={{ fontSize: 40, color: '#9E9E9E' }} />
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handlePhotoAdd}
          />
        </Button>
      </Box>
    );

    return uploadAreas;
  };

  const handleNavigateToDashboard = () => {
    const areaSection = sessionStorage.getItem("area_section");
    if(areaSection !== 'null') {
      navigate(`/modules/${areaSection}/dashboard`);
    } else {
      navigate("/modules/ceop/dashboard");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <DefaultLayout title='Controle de equipamentos'>
          <Box sx={{ position: "absolute", width:'100%', alignContent:'flex-end' }}>
            <Button
              onClick={handleNavigateToDashboard}
              variant="contained"
              color="primary"
              startIcon={<ArrowForwardIcon />}
              sx={{
                position: "absolute",
                top: 'inherit',
                left: '85%',
                marginRight: 10,
                backgroundColor: "#00002E",
                zIndex:'9999999'
              }}
            >
              Dashboard
            </Button>
            
          </Box>
          <Box
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    backgroundColor: "#E0E0E0",
    position: "relative",
    minHeight: "100vh", // Garante que ocupe pelo menos a altura da tela
  }}
>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              maxWidth: 500,
              width: "100%",
              position: "relative",
              padding: 4,
              backgroundColor: "#fff",
            }}
          >
            {!isSummaryVisible && (
              <>
                {equipmentInfo && equipmentInfo.currentStatus === 1 ? (
                  <ReceiveIcon
                    sx={{
                      position: "absolute",
                      display: "grid",
                      mr: 5,
                      top: 20,
                      left: 20,
                    }}
                  />
                ) : equipmentInfo && equipmentInfo.currentStatus === 0 ? (
                  <DeliveryIcon
                    sx={{
                      position: "absolute",
                      display: "grid",
                      mr: 5,
                      top: 20,
                      left: 20,
                    }}
                  />
                ) : equipmentInfo && (equipmentInfo.currentStatus === 66 || equipmentInfo.currentStatus === 99) ? (
                  <BrokenIcon
                    sx={{
                      position: "absolute",
                      display: "grid",
                      mr: 5,
                      top: 20,
                      left: 20,
                    }}
                  />
                ) : !isFirstFieldDisabled || !equipmentInfo ? (
                  <KeyboardIcon
                    sx={{
                      position: "absolute",
                      display: "grid",
                      mr: 5,
                      top: 20,
                      left: 20,
                    }}
                  />
                ) : (
                  <KeyboardIcon
                    sx={{
                      position: "absolute",
                      display: "grid",
                      mr: 5,
                      top: 20,
                      left: 20,
                    }}
                  />
                )}
<Typography
  variant="body2"
  color="#00002e"
  sx={{ mr: 5, top: 22, left: 50, position: "absolute" }}
>
  {!equipmentInfo || !isFirstFieldDisabled ? (
    <>Digite o código do equipamento</>
  ) : equipmentInfo.currentStatus === 1 ? (
    <>
      <strong>DEVOLUÇÃO </strong>
    </>
  ) : equipmentInfo.currentStatus === 0 ? (
    <>
      <strong>ENTREGA </strong>
    </>
  ) : equipmentInfo.currentStatus === 66 ? (
    <>
      Equipamento <strong> QUEBRADO </strong>
    </>
  ) : equipmentInfo.currentStatus === 99 ? (
    <>
      Equipamento <strong> INATIVO </strong>
    </>
  ) : equipmentInfo.currentStatus === null ? (
    <>Digite o código do equipamento</>
  ) : null}
</Typography>
                <IconButton
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 3,
                    right: 8,
                    margin: 1,
                    backgroundColor: "#E0E0E0",
                    color: "#00002e",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="body2" color="#00002e">
                    Leitor QRCode{" "}
                  </Typography>
                  <QrCodeScannerIcon sx={{ marginLeft: 1 }} />
                </IconButton>
              </>
            )}
            {isCameraVisible && (
              <Box
                sx={{
                  width: "100%",
                  height: 300,
                  backgroundColor: "#E0E0E0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 2,
                  color: "#00002e",
                }}
              >
                <Typography variant="body2" color="#00002e">
                  Camera Placeholder
                </Typography>
              </Box>
            )}
            {!isSummaryVisible && (
              <>
                <Divider sx={{ mt: 5 }} />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Equipamento"
                  value={firstFieldValue}
                  placeholder="Digite o código do equipamento aqui"
                  onChange={(e) => setFirstFieldValue(e.target.value)}
                  onKeyPress={handleFirstFieldKeyPress}
                  disabled={isFirstFieldDisabled}
                  sx={{ mb: 2, mt: 4, color: "#00002e" }}
                  inputRef={firstFieldRef}
                />
                {isSecondFieldVisible && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="CRACHÁ"
                    placeholder="Digite o crachá do colaborador aqui"
                    value={secondFieldValue}
                    onChange={(e) => setSecondFieldValue(e.target.value)}
                    onKeyPress={handleSecondFieldKeyPress}
                    sx={{ mb: 2, mt: 4 }}
                    inputRef={secondFieldRef}
                  />
                )}
                <Divider sx={{ mb: 2 }} />
              </>
            )}
            {isSummaryVisible && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h4"
                  fontSize={"medium"}
                  gutterBottom
                  sx={{ color: "#00002e", textAlign: "center" }}
                >
                  {equipmentInfo && equipmentInfo.currentStatus === 0 ? (
                    <> RESUMO DA <strong> ENTREGA </strong> </>
                  ) : equipmentInfo && equipmentInfo.currentStatus === 1 ? (
                    <> RESUMO DA <strong> DEVOLUÇÃO </strong> </>
                  ) : (
                    ""
                  )}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="h6" gutterBottom sx={{ color: "#00002e" }}>
                  Equipamento: <strong>{firstFieldValue}</strong>
                </Typography>
                {equipmentInfo && (
                  <>
                    <Typography variant="h6" gutterBottom sx={{ color: "#00002e" }}>
                      Tipo: <strong>{equipmentInfo.type}</strong>
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{ color: "#00002e" }}>
                      Modelo: <strong>{equipmentInfo.model}</strong>
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{ color: "#00002e" }}>
                      Fabricante: <strong>{equipmentInfo.manufacturer}</strong>
                    </Typography>
                  </>
                )}
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography variant="h6" sx={{ color: "#00002e" }}>
                  {equipmentInfo && equipmentInfo.currentStatus === 0 ? (
                    <>Entregue para: <strong>{secondFieldValue} - {employeeInfo?.name} - {employeeInfo?.company_role}</strong></>
                  ) : (
                    <>Devolvido por: <strong>{secondFieldValue} - {employeeInfo?.name} - {employeeInfo?.company_role}</strong></>
                  )}
                </Typography>

                <Divider sx={{ mt: 2, mb: 2 }} />
                {isSummaryVisible &&
                  equipmentInfo &&
                  equipmentInfo.currentStatus === 1 && (
                    <Box sx={{ mb: 2 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addObservations}
                            onChange={(e) => setAddObservations(e.target.checked)}
                          />
                        }
                        label="Equipamento com avarias ou observações?"
                      />
                      {addObservations && (
                        <Box sx={{ mt: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Observações"
                            placeholder="Descreva quais foram os danos apresentados"
                            multiline
                            rows={3}
                            value={observations}
                            onChange={(e) => {
                              setObservations(e.target.value);
                            }}
                            sx={{ mb: 2 }}
                          />
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}
                          >
                            {renderPhotoUploadAreas()}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                <Typography variant="caption" sx={{ color: "#00002e" }}>
                  Operação realizada por: 
                  <strong>{`${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname} em ${new Date().toLocaleString()}`}</strong>
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
                gap: 1,
              }}
            >
              {(isSecondFieldVisible || isSummaryVisible) && (
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  size="small"
                  sx={{ backgroundColor: "#E0E0E0", color: "#00002e" }}
                  disabled={isLoading || isSubmitting}
                >
                  Cancelar
                </Button>
              )}
              <Button
                onClick={isSummaryVisible ? confirmOperation : handleNextClick}
                variant="contained"
                color="success"
                size="small"
                disabled={
                  isLoading ||
                  isSubmitting ||
                  (isSummaryVisible && (equipmentInfo?.currentStatus === 66 || equipmentInfo?.currentStatus === 99 || equipmentInfo?.currentStatus === 77)) ||
                  (isSummaryVisible && addObservations && (observations.length < 10 || photos.length < 1))
                }
                startIcon={isLoading || isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
              >
                {isSummaryVisible ? "Confirmar" : "Seguinte"}
              </Button>
            </Box>
          </Paper>
          <Snackbar
            open={alertOpen}
            autoHideDuration={3000}
            onClose={() => setAlertOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{
              position: "absolute",
              bottom: "-600px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1,
            }}
          >
            <Alert
              variant="filled"
              onClose={() => setAlertOpen(false)}
              severity={alertSeverity}
              sx={{
                width: "fit-content",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "clip",
              }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
      </Box>
      </DefaultLayout>
    </Box>
  );
};

export default ScanEquipmentPage;
