import { useState, useEffect } from 'react';

const useFetchOptions = () => {
  const [types, setTypes] = useState([]);
  const [models, setModels] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem('accessToken');

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://serverwinglet.winglet.app/api/get_options', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`, // Adicione 'Bearer' antes do token se necessário para autorização
          },
        });

        if (response.ok) {
          const data = await response.json();
          
          // Aplicando filtro para remover valores nulos e duplicados
          const uniqueTypes = Array.from(new Set(data.types?.filter(Boolean)));
          const uniqueModels = Array.from(new Set(data.models?.filter(Boolean)));
          const uniqueManufacturers = Array.from(new Set(data.manufacturers?.filter(Boolean)));

          setTypes(uniqueTypes);
          setModels(uniqueModels);
          setManufacturers(uniqueManufacturers);
        } else {
          console.error('Falha ao buscar opções, status:', response.status);
        }
      } catch (error) {
        console.error('Erro ao buscar opções:', error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchOptions();
    } else {
      console.warn('Token de acesso não encontrado.');
      setLoading(false);
    }
  }, [token]);

  return { types, models, manufacturers, loading };
};

export default useFetchOptions;
