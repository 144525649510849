import { useState, useEffect } from 'react';
import { fetchEquipments } from './ceopEquipments';

const useAddEquipment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const token = sessionStorage.getItem('accessToken');
  const [equipments, setEquipments] = useState([]);



  const addEquipment = async (equipmentData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    const userArea = sessionStorage.getItem('area_section') // Corrigido para acessar user_areasection de forma segura


    try {

      const response = await fetch(`https://serverwinglet.winglet.app/api/add_equipment/${userArea}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify(equipmentData),
      });

      if (response.ok) {
        setSuccess(true);
        // Atualiza os equipamentos após adicionar um novo
        fetchEquipments();
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Falha ao adicionar equipamento');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        const equipmentsData = await fetchEquipments(token);
        setEquipments(equipmentsData);
      }
    };

    fetchData();

    const ws = new WebSocket('wss://serverwinglet.winglet.app');

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'ADD_EQUIPMENT') {
        fetchData(); // Handle the real-time update
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };

    return () => {
      ws.close();
    };
  }, [token]);

  return { addEquipment, loading, error, success, equipments };
};

export default useAddEquipment;
