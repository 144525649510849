import { useState, useEffect } from 'react';
import useUser from '../useUser';

const token = sessionStorage.getItem('accessToken');
const area = sessionStorage.getItem('area_section'); // Corrigido para acessar user_areasection de forma segura;


export const fetchEquipments = async () => {
  try {
    const response = await fetch(`https://serverwinglet.winglet.app/api/ceop_equipments/${area}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${token}`,
      },
    });
    
    if (response.ok) {
      const data = await response.json();
      return data.equipments; // Retorna os equipamentos
    } else {
      console.error('Failed to fetch equipment data');
      return null;
    }
  } catch (error) {
    console.error('Error fetching equipment data:', error);
    return null;
  }
}

const useCeopEquipments = () => {
  const [equipments, setEquipments] = useState([]);
  const [user, setUser] = useState(null);
  const userBadge = sessionStorage.getItem('user_badge');
  const fetchedUser = useUser(userBadge);

  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
    const fetchData = async () => {
      if (user) {
        const equipmentsData = await fetchEquipments();
        setEquipments(equipmentsData || []);
      }
    };

    fetchData();

    const ws = new WebSocket('wss://serverwinglet.winglet.app');

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'UPDATE_EQUIPMENT' || data.type === 'ADD_EQUIPMENT') {
        fetchData(); // Handle the real-time update
      } else if (data.type === 'LOGOUT') {
        setEquipments([]);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };

    return () => {
      ws.close();
    };
  }, [user, fetchedUser]);

  return equipments;
};

export default useCeopEquipments;