import { useState, useEffect, useCallback } from 'react';
import fetchUser from '../../useAllUsers'; // Verifique se `fetchUser` é uma função e não um hook.

const useAddUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const token = sessionStorage.getItem('accessToken');
  const [users, setUsers] = useState([]);

  // Função para buscar usuários
  const fetchData = useCallback(async () => {
    if (token) {
      try {
        const userData = await fetchUser(token); // Certifique-se que `fetchUser` seja uma função comum
        setUsers(userData);
      } catch (err) {
        setError('Falha ao buscar usuários');
      }
    }
  }, [token]);

  const addUser = async (userData) => {
    console.log('userData', userData);
    setLoading(true);
    setError(null);
    setSuccess(false);
    const userArea = sessionStorage.getItem('area_section');

    try {
      const response = await fetch(`https://serverwinglet.winglet.app/api/add_user/${userArea}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        setSuccess(true);
        await fetchData(); // Atualiza a lista de usuários após adicionar com sucesso
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Falha ao adicionar usuário');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    const ws = new WebSocket('wss://serverwinglet.winglet.app');

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'UPDATE_USERS') {
        fetchData(); // Handle the real-time update
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };

    return () => {
      ws.close();
    };
  }, [token, fetchData]);

  return { addUser, loading, error, success, users };
};

export default useAddUser;
