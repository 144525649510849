import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      let token = sessionStorage.getItem('accessToken');
      if (!token) {
        navigate('/'); // Navigate to login page if no token
        return;
      }

      const isTokenExpired = (token) => {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return Date.now() >= payload.exp * 1000;
      };

      if (isTokenExpired(token)) {
        const response = await fetch('https://serverwinglet.winglet.app/api/refresh-token', {
          method: 'POST',
          credentials: 'include', // Ensure cookies are included
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          sessionStorage.setItem('accessToken', data.accessToken);
          token = data.accessToken;
        } else {
          console.error('Failed to refresh token');
          navigate('/'); // Navigate to login page if token refresh fails
          return;
        }
      }
    };

    checkAuth();
  }, [navigate]);
};

export default useAuth;