// components/DefaultLayout.js
import React, { useState} from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  CssBaseline,
} from "@mui/material";
import {
  Close as CloseIcon,
  Minimize as MinimizeIcon,
} from "@mui/icons-material";
import PersistentDrawer from "./Drawer"; // Componente do Drawer
import WingletLogoSmall from "./WingletLogoSmall"; // Componente do logo
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Cookies from "js-cookie";




const DefaultLayout = ({ children, title }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [isMinimizing, setIsMinimizing] = useState(false);


  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  const handleLogout = async () => {
    sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("area_section");

      // Invalidate the refresh token on the server
      await fetch('https://serverwinglet.winglet.app/api/logout', {
      method: 'POST',
      credentials: 'include', // Ensure cookies are included
      headers: {
      'Content-Type': 'application/json',
  },
  body: JSON.stringify({ badge: sessionStorage.getItem("user_badge") }),
});

// Remove the refresh token from the client and navigate to the login page
Cookies.remove('refreshToken');
sessionStorage.removeItem("user_badge");
navigate("/");
  };

    // Handle minimize action
    const handleMinimize = () => {
        setIsMinimizing(true);
      };
    
      // Handle animation complete
      const handleAnimationComplete = () => {
        if (isMinimizing) {
          navigate("/modules"); // Navigate after the animation completes
        }
      };

  return (
    <motion.div
    initial={{ opacity: 1, scale: 1 }}
    animate={
      isMinimizing
        ? { opacity: 0, scale: 0.5, x: 200, y: 200 }
        : { opacity: 1, scale: 1 }
    }
    transition={{ duration: 0.2 }}
    onAnimationComplete={handleAnimationComplete}
  >
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", minWidth:'100hv', margin:'0px'}}>
      <CssBaseline />
      <AppBar position="static" color="#FFF" elevation={1} sx={{height: {md:'50%', sm: '25%'}}}>
        <Toolbar variant="dense">
          <PersistentDrawer open={drawerOpen} onClose={toggleDrawer}/>
          <WingletLogoSmall width={"36px"} />
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              flexGrow: 1,
              marginLeft: 2,
              color: "#00002E",
              fontSize: {xs: '15px', sm:"22px"},
              fontWeight: "700",
            }}
          >
            {title || "Dashboard"}
          </Typography>
          <Box sx={{ display: "flex", ml: "auto" }}>
            <IconButton size="small" sx={{ color: "text.secondary" }} onClick={handleMinimize}>
              <MinimizeIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" sx={{ color: "text.secondary", ml: 1 }} onClick={handleLogout}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{ flexGrow: 1, backgroundColor: "#E0E0E0", padding: 3 }}
      >
        {children}
      </Box>
    </Box>
    </motion.div>
  );
};

export default DefaultLayout;
