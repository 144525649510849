import React, { useState, useEffect, useMemo, useCallback } from "react";
import { QRCodeCanvas } from 'qrcode.react';

import {
  Typography,
  Container,
  Grid,
  Button,
  Card,
  Box,
  IconButton,
  CssBaseline,
  Tabs,
  Tab,
  Divider,
  TextField,
  Popper,
  ClickAwayListener,
  Alert, 
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
  ArrowDropDown as ArrowDropDownIcon,
  QrCodeScanner as QrCodeScannerIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Assignment as AssignmentIcon,
  DeleteForever as DeletIcon,
  Edit as EditIcon,
  QrCodeOutlined as QrCodeOutlinedIcon,
  FileDownloadDoneOutlined as CheckMarckIcon,
  WarningOutlined as WarningIcon,
  DownloadOutlined as DownloadIcon,
  LocalPrintshopOutlined as Printicon,
  CheckCircleOutline as AvailableIcon,
  Block as InactiveIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import ceopEquipments, { fetchEquipments } from '../../../hooks/ceop/ceopEquipments';
import AddItemModal from "../../../components/ceop/AddItemModal";
import AddEquipmentModal from "../../../components/ceop/AddEquipmentModal";
import * as XLSX from 'xlsx';
import moment from 'moment';
import DefaultLayout from "../../../components/DefaultLayout";
import { ptBR } from '@mui/x-data-grid/locales';
import EquipmentStatsModal from "../../../components/ceop/EquipmentStatsModal";
import icon12h from '../../../assets/icons/12hicon.png';
import icon24h from '../../../assets/icons/24hicon.png';


const CEOPEquipmentDashboard = () => {
  useAuth();
  // Fetch user data using user Context
  const [filteredRows, setFilteredRows] = useState([]);
  const [user, setUser] = useState(null);
  const userBadge = sessionStorage.getItem('user_badge');
  const fetchedUser = useUser(userBadge);
  const equipments = ceopEquipments([]);
  
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [addItemAnchorEl, setAddItemAnchorEl] = useState(null);
  const [cardValues, setCardValues] = useState({
    total: { value: 0, change: "0%" },
    available: { value: 0, change: "0%" },
    inUse: { value: 0, change: "0%" },
    lost: { value: 0, change: "0%" },
    maintenance: { value: 0, change: "0%" },
    overdue12h: { value: 0, change: "0%" },
    overdue24h: { value: 0, change: "0%" },
  });

  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInactiveOpen, setModalInactiveOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [inactiveEquipment, setInactiveEquipment] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editEquipment, setEditEquipment] = useState(null);  // To store the equipment being edited
  const [selectedRows, setSelectedRows] = useState([]); // To store selected equipment
  const [detailsModalOpen, setDetailsModalOpen] = useState(false); // Modal state for showing details
  const [equipmentDetails, setEquipmentDetails] = useState(null);  // Store details of the selected equipment
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [qrCodeEquipment, setQrCodeEquipment] = useState(null); // Store equipment for QRCode generation
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [addEquipmentModalOpen, setAddEquipmentModalOpen] = useState(false);
  const [exportConfirmOpen, setExportConfirmOpen] = useState(false); // Novo estado para o diálogo de confirmação
  const [hasHandledSuccess, setHasHandledSuccess] = useState(false); // Estado para rastrear se a função já foi chamada
  const [equipmentToDelete, setEquipmentToDelete] = useState(null);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [bulkConfirmOpen, setBulkConfirmOpen] = useState(false);
  const [bulkActionType, setBulkActionType] = useState(null);

  useEffect(() => {
    if (fetchedUser && fetchedUser !== user) {
      setUser(fetchedUser);
    }
  
    const areaSection = sessionStorage.getItem('area_section');
    if (areaSection) {
      const fetchInitialEquipments = async () => {
        if (!sessionStorage.getItem('equipments')) {
          const fetchedEquipments = await fetchEquipments();
          sessionStorage.setItem('equipments', JSON.stringify(fetchedEquipments));
          setFilteredRows(fetchedEquipments || []);
        } else {
          setFilteredRows(JSON.parse(sessionStorage.getItem('equipments')));
        }
      };
      fetchInitialEquipments();
    }
  }, [fetchedUser, user]);// Use um array vazio para rodar apenas quando o componente for montado

  const handleStatCardClick = (tabName) => {
    
    setSelectedTab(tabName);
  };
  
  const titleToFilterMap = {
    'Total': 0,
    'Disponíveis': 1,
    'Em uso': 2,
    'Manutenção': 3,
    'Inativos': 4,
    '+12h': 5,
    '+24h': 6,
  };

  const handleBulkAvailable = () => {
    if (Array.isArray(equipments)) {
      selectedRows.forEach(id => {
        const equipment = equipments.find(eq => eq.id === id);
        if (equipment) setEquipmentAvailable(equipment);
      });
    }
    setSpeedDialOpen(false);
  };
  
  const handleBulkInactive = () => {
    if (Array.isArray(equipments)) {
      selectedRows.forEach(id => {
        const equipment = equipments.find(eq => eq.id === id);
        if (equipment) setEquipmentInactive(equipment);
      });
    }
    setSpeedDialOpen(false);
  };
  
  const handleBulkDelete = () => {
    if (Array.isArray(equipments)) {
      selectedRows.forEach(id => {
        const equipment = equipments.find(eq => eq.id === id);
        if (equipment) setEquipmentDelete(equipment);
      });
    }
    setSpeedDialOpen(false);
  };
  
  const handleAddSuccess = (message) => {
    if (!hasHandledSuccess) {
      setAlertMessage(message);
      setAlertOpen(true);
      setAlertSeverity('success');
      setHasHandledSuccess(true); // Marcar como chamada
    }
  };
  const StatCard = ({ title, value, change, stats }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleOpenModal = (event) => {
      event.stopPropagation();
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    return (
      <>
        <Card
          sx={{
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            width: { xs: "50px", md: "100px", lg: "200px" },
            height: "80px",
            display: { xs: "none", md: "flex", lg: "flex" },
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 1,
            boxShadow: "0 0 0 1px #CCCCCC",
            marginTop: 0,
            cursor: "pointer",
          }}
          onClick={() => handleStatCardClick(titleToFilterMap[title])}
                  >
          <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" sx={{marginTop:-1}}>
            <Typography variant="subtitle2" color="#00002E" >
              {title}
            </Typography>
            <IconButton onClick={handleOpenModal} sx={{ display: 'flex' }} >
              <ArrowDropDownIcon color="action" disabled/>
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end">
            <Typography variant="h5" component="span" sx={{ color: "#00002E" }}>
              {value}
            </Typography>
            <Typography
              variant="body2"
              color={change.includes("-") ? "error.main" : "success.main"}
            >
              {change}
            </Typography>
          </Box>
        </Card>
        <EquipmentStatsModal open={isModalOpen} onClose={handleCloseModal} stats={stats} />
      </>
    );
  };




  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  
  const handleClickAway = () => {
    setSearchOpen(null)
  };


  const handleSearchClick = (event) => {
    setSearchAnchorEl(event.currentTarget);
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddItemClick = (event) => {
    setAddItemAnchorEl(event.currentTarget);
  };

  const handleAddItemClose = () => {
    setAddItemAnchorEl(null);
  };

  const handleAddEquipmentClick = () => {
    handleAddItemClose();
    setAddEquipmentModalOpen(true);
  };

  const handleAddOtherItemsClick = () => {
    handleAddItemClose();
    setAddItemModalOpen(true);
  };

  const handleEditClick = useCallback((row) => {
    setEditEquipment(row);
    setEditModalOpen(true);
  }, []);

  const columns = useMemo(() =>  [
    {
      field: "status",
      minWidth: 120,
      headerName: "Status",
      renderCell: (params) => {
        const { row } = params;
        let statusText = "";
        let warningIcon = null;
  
        // Definindo o texto do status
        switch (row.status) {
          case 0:
            statusText = "DISPONÍVEL";
            break;
          case 1:
            statusText = "EM USO";
            break;
          case 66:
            statusText = "DANIFICADO";
            break;
          case 99:
            statusText = "INATIVO";
            break;
          default:
            statusText = "DESCONHECIDO"; // Fallback if the status is not recognized
            break;
        }
  
        if (row.lastRegistry) {
          const lastRegistryMoment = moment(row.lastRegistry, "DD/MM/YYYY, HH:mm:ss");
          if (lastRegistryMoment.isValid()) {
            const diffInHours = moment().diff(lastRegistryMoment, 'hours');
    
            if (diffInHours >= 24 && row.status === 1 && (selectedTab === 0 || selectedTab === 2)) {
              warningIcon = (
                <img 
                  src={icon24h}
                  className="blink"
                  alt="warning"
                />
              );
            } else if (diffInHours > 12 && diffInHours < 24 && row.status === 1 && selectedTab === 0) {
              warningIcon = (
                <img 
                  src={icon12h}
                  className="blink"
                  alt="warning"
                />
              );
            }
          }
        }
  
        return (
<Box 
  sx={{ 
    display: 'flex', 
    alignItems: 'center', // Vertical centering
    justifyContent: 'center', // Horizontal centering
    gap: 0.5,
    width: '100%', // Ensure box takes full width of cell
    height: '100%' // Ensure box takes full height of cell
  }}
>
  <Typography 
    sx={{
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
    }}
  >
    {statusText}
  </Typography>
  {warningIcon}
</Box>
        );
      },
    },
    { field: "code", headerName: "ID", flex: 1 },
    { field: "equipment", headerName: "Equipamento", flex: 1 },
    { field: "usedBy", headerName: selectedTab === 1 ? 'Sob posse de' : 'Último uso por', flex: 1 },
    { field: "lastRegistry", headerName: selectedTab === 5 || selectedTab === 6 ? 'Em uso por' : 'Último uso', flex: 1 },
    { field: "department", headerName: "Setor", flex: 1 },
    { field: "serialnumber", headerName: "Número de Série", flex: 1 },
    { field: "companynumber", headerName: "Patrimônio", flex: 1 },
    ...(user?.userInfo?.user_accesslevel.level !== "user"
      ? [
          {
            field: "actions",
            headerName: "Ações",
            width: 250,
            sortable: false,
            renderCell: (params) => (
              <Box
                display="flex"
                justifyContent="space-around"
                width="100%"
                padding={1}
                height="100%"
              >
                <IconButton
                  aria-label="edit"
                  disabled={params.row.status === 1}
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  onClick={() => handleEditClick(params.row)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  disabled={
                    params.row.status === 66 ||
                    params.row.status === 99 ||
                    params.row.status === 1
                  }
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  onClick={() => handleDeleteClick(params.row)}

                >
                  <DeletIcon />
                </IconButton>
                <IconButton
                  aria-label="qr-code"
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  onClick={() => handleQRCodeClick(params.row)}
                >
                  <QrCodeOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="checkmark"
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  disabled={
                    params.row.status !== 66 ||
                    params.row.status !== 99 ||
                    params.row.status !== 0 

                  }
                  onClick={() => {
                    setSelectedEquipment(params.row);
                    setModalOpen(true);
                  }}
                >
                  <CheckMarckIcon />
                </IconButton>
                <IconButton
                  aria-label="warning"
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  disabled={params.row.status === 66 || params.row.status === 1}
                  onClick={() => {
                    setInactiveEquipment(params.row);
                    setModalInactiveOpen(true);
                  }}
                >
                  <WarningIcon />
                </IconButton>
              </Box>
            ),
          }
        ]
      : []),
  ], [selectedTab, user?.userInfo?.user_accesslevel.level]);
  
  const handleConfirm = () => {
    if (selectedEquipment) {
      setEquipmentAvailable(selectedEquipment); // Call the function to update status
    }
    setModalOpen(false); // Close the modal
  };
  
  const handleClose = () => {
    setModalOpen(false); // Close the modal without doing anything
  };

  const rows = useMemo(() => {
    return (Array.isArray(equipments) ? equipments : [])
      .filter(equipment => equipment.currentStatus !== 77)
      .map((equipment) => {
        // Ordene o histórico em ordem decrescente de data
        const sortedHistory = Array.isArray(equipment.history) ? equipment.history.slice().sort((a, b) => new Date(b.date) - new Date(a.date)) : [];
    
        // Pegue a entrada de histórico mais recente
        const lastHistoryEntry = sortedHistory[0] || {};
  
        return {
          id: equipment.id,
          code: equipment.code,
          status: equipment.currentStatus,
          equipment: equipment.type,
          usedBy: `${lastHistoryEntry.to_from} - ${lastHistoryEntry.to_from_fullName}` || "Desconhecido",
          lastRegistry: lastHistoryEntry.date ? new Date(lastHistoryEntry.date).toLocaleString() : null, // Converta para objeto Date
          department: equipment.department,
          description: equipment.description,
          manufacturer: equipment.manufacturer,
          model: equipment.model,
          serialnumber: equipment.serialNumber,
          type: equipment.type,
          companynumber: equipment.companyNumber,
          owner: equipment.owner,
        };
      });
  }, [equipments]);
  
  // Effect to filter rows based on search term and selected tab
  useEffect(() => {
    let filtered = Array.isArray(rows) ? rows : [];
  
    if (searchTerm) {
      filtered = filtered.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  
    const currentTime = moment(); // Use moment to get the current time
  
    switch (selectedTab) {
      case 1:
        // Disponível
        filtered = filtered.filter((row) => row.status === 0);
        break;
      case 2:
        // Em Uso
        filtered = filtered.filter((row) => row.status === 1);
        break;
      case 3:
        // Manutenção
        filtered = filtered.filter((row) => row.status === 66);
        break;
      case 4:
        // Extraviados
        filtered = filtered.filter((row) => row.status === 99);
        break;
      case 5:
        // +12h sem devolução
        filtered = filtered.filter((row) => {
          if (row.status === 1) {
            const lastRegistryMoment = moment(row.lastRegistry, "DD/MM/YYYY, HH:mm:ss");
            if (!lastRegistryMoment.isValid()) {
              console.error(`Invalid date format for lastRegistry: ${row.lastRegistry}`);
              return false;
            }
            const diffInHours = currentTime.diff(lastRegistryMoment, "hours");
            return diffInHours > 12 && diffInHours < 24;
          }
          return false;
        });
  
        // Atualizar lastRegistry para mostrar a diferença em horas
        filtered = filtered.map((row) => {
          const lastRegistryMoment = moment(row.lastRegistry, "DD/MM/YYYY, HH:mm:ss");
          if (lastRegistryMoment.isValid()) {
            const diffInHours = currentTime.diff(lastRegistryMoment, "hours");
            return { ...row, lastRegistry: `${diffInHours} horas` };
          }
          return row;
        });
        break;
  
      case 6:
        // +24h sem devolução
        filtered = filtered.filter((row) => {
          if (row.status === 1) {
            const lastRegistryMoment = moment(row.lastRegistry, "DD/MM/YYYY, HH:mm:ss");
            if (!lastRegistryMoment.isValid()) {
              console.error(`Invalid date format for lastRegistry: ${row.lastRegistry}`);
              return false;
            }
            const diffInHours = currentTime.diff(lastRegistryMoment, "hours");
            return diffInHours >= 24;
          }
          return false;
        });
  
        // Atualizar lastRegistry para mostrar a diferença em horas
        filtered = filtered.map((row) => {
          const lastRegistryMoment = moment(row.lastRegistry, "DD/MM/YYYY, HH:mm:ss");
          if (lastRegistryMoment.isValid()) {
            const diffInHours = currentTime.diff(lastRegistryMoment, "hours");
            return { ...row, lastRegistry: `${diffInHours} horas` };
          }
          return row;
        });
        break;
  
      default:
        break;
    }
  
    setFilteredRows(filtered);
  }, [searchTerm, rows, selectedTab]);

// Effect to calculate card values once when equipments change
useEffect(() => {
  let overdue12hCount = 0;
  let overdue24hCount = 0;

  const currentTime = moment(); // Use moment to get the current time

  
  equipments.forEach((equipment) => {
    // Only consider equipment that is currently in use
    if (equipment.currentStatus === 1) {
      // Sort the history for each equipment
      const sortedHistory = (equipment.history || []).sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      // Get the most recent history entry
      const lastHistoryEntry = sortedHistory[0];

      if (lastHistoryEntry && lastHistoryEntry.date) {
        const lastRegistryMoment = moment(lastHistoryEntry.date);
        if (lastRegistryMoment.isValid()) {
          const diffInHours = currentTime.diff(lastRegistryMoment, 'hours');

          if (diffInHours >= 24) {
            overdue24hCount++;
          } else if (diffInHours > 12 && diffInHours < 24) {
            overdue12hCount++;
          }
        }
      }
    }
  });

// Ensure equipments is an array
const equipmentsArray = Array.isArray(equipments) ? equipments : [];

// Calculate counts
const totalDeleted = equipmentsArray.filter((e) => e.currentStatus === 77).length;
const total = equipmentsArray.length - totalDeleted;
const available = equipmentsArray.filter((e) => e.currentStatus === 0 && e.currentStatus !== 77).length;
const inUse = equipmentsArray.filter((e) => e.currentStatus === 1 && e.currentStatus !== 77).length;
const lost = equipmentsArray.filter((e) => e.currentStatus === 99 && e.currentStatus !== 77).length;
const maintenance = equipmentsArray.filter((e) => e.currentStatus === 66 && e.currentStatus !== 77).length;

setCardValues({
  total: { value: total, change: `` },
  available: { value: available, change: `${Math.round((available * 100) / total)}%` },
  inUse: { value: inUse, change: `${Math.round((inUse * 100) / total)}%` },
  lost: { value: lost, change: `${Math.round((lost * 100) / total)}%` },
  maintenance: { value: maintenance, change: `${Math.round((maintenance * 100) / total)}%` },
  overdue12h: { value: overdue12hCount, change: `${Math.round((overdue12hCount * 100) / inUse)}%`},
  overdue24h: { value: overdue24hCount, change: `${Math.round((overdue24hCount * 100) / inUse)}%`},
});
}, [equipments, setCardValues]);

  const setEquipmentAvailable = async (equipment) => {
    try {
      const response = await fetch("https://serverwinglet.winglet.app/api/update_equipment", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          id: equipment.id,
          equipment: equipment.code,
          status: 0,  // Mark as available
          user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`,
          funcao: user?.userInfo?.user_companyfunction,
          badge: 'N/A',
          employeeName: "N/A",
          observations: 'Equipamento retornado de dano',
          photos: [], // URLs or empty if not applicable
        }),
        headers: {
          Authorization: `${sessionStorage.getItem('accessToken')}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        setAlertOpen(true);
        setAlertMessage(`Operação realizada com sucesso para o equipamento ${equipment.code}`);
        setAlertSeverity('success');
      } else {
        setAlertOpen(true);
        setAlertMessage("Ops! Algo deu errado. Tente novamente");
        setAlertSeverity('warning');
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertMessage(`Ops! Não foi possível completar a operação. Erro: ${error.message}`);
      setAlertSeverity('error');
    }
  };

  const ConfirmDialog = ({ open, handleClose, handleConfirm, equipment }) => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirmar Atualização de Status"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem certeza de que deseja <strong>disponibilizar o equipamento{" "}
          {equipment?.code}</strong> para uso, novamente?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'10px'}}>
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const EditEquipmentDialog = ({ open, handleClose, equipment, handleSave }) => {
    const [formData, setFormData] = useState({
      id: equipment?.id || '',
      code: equipment?.code || '',
      type: equipment?.type || '',
      description: equipment?.description || '',
      model: equipment?.model || '',
      manufacturer: equipment?.manufacturer || '',
      department: equipment?.department || '',
      serialnumber: equipment?.serialnumber || '',
      companynumber: equipment?.companynumber || '',
      user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`
      // Add more fields as needed
    });
  
    useEffect(() => {
      if (equipment) {
        setFormData({
          id: equipment.id,
          code: equipment.code,
          type: equipment.type,
          description: equipment.description,
          model: equipment.model,
          manufacturer: equipment.manufacturer,
          department: equipment.department,
          serialNumber: equipment.serialnumber,
          companyNumber: equipment.companynumber,
          user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`


          // Pre-fill other fields
        });
      }
    }, [equipment]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = () => {
      // Call the save handler passed as a prop
      handleSave(formData);
      handleClose();  // Close the modal after saving
    };
  
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Equipamento</DialogTitle>
        <DialogContent>
          <TextField
            label="Código"
            name="code"
            disabled={true}
            value={formData.code}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Tipo"
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Descrição"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Modelo"
            name="model"
            value={formData.model}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Fabricante"
            name="manufacturer"
            value={formData.manufacturer}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Departamento"
            name="department"
            value={formData.department}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Número de Série"
            name="serialNumber"
            onChange={handleInputChange}
            disabled={true}
            value={formData.serialnumber}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Patrimônio Nº"
            name="companyNumber"
            onChange={handleInputChange}
            disabled={true}
            value={formData.companynumber}
            fullWidth
            margin="dense"
          />
          {/* Add more fields as necessary */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>Cancelar</Button>
          <Button onClick={handleSubmit} sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus>Salvar</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSaveEdit = async (updatedEquipment) => {
    try {
      const response = await fetch(`https://serverwinglet.winglet.app/api/update_equipment/${updatedEquipment.id}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(updatedEquipment),
        headers: {
          Authorization: `${sessionStorage.getItem('accessToken')}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        // Refresh the equipment data (or update it in the UI)
        setAlertOpen(true);
        setAlertMessage(`Equipamento ${updatedEquipment.code} atualizado com sucesso`);
        setAlertSeverity('success');
      } else {
        setAlertOpen(true);
        setAlertMessage("Ops! Algo deu errado ao atualizar o equipamento.");
        setAlertSeverity('warning');
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertMessage(`Erro ao atualizar equipamento: ${error.message}`);
      setAlertSeverity('error');
    }
  };
  
  const handleRowSelection = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const EquipmentDetailsDialog = ({ open, handleClose, equipment }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageModalOpen, setImageModalOpen] = useState(false);
  
    if (!equipment) {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Detalhes do Equipamento</DialogTitle>
          <DialogContent>
            <Typography>Nenhum equipamento selecionado.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      );
    }
  
    // Sort the history by date (descending)
    const sortedHistory = [...(equipment.history || [])].sort((a, b) => new Date(b.date) - new Date(a.date));
  
    const handleImageClick = (photo) => {
      setSelectedImage(photo);
      setImageModalOpen(true);
    };
  
    const handleImageModalClose = () => {
      setImageModalOpen(false);
      setSelectedImage(null);
    };
  
    const exportToExcel = () => {
      const data = sortedHistory.map(entry => ({
        Data: new Date(entry.date).toLocaleString(),
        'Usuário Responsável': entry.user,
        'Ação Realizada': entry.action,
        'Observações': entry.observations,
        'Fotos': entry.photos ? entry.photos.join(', ') : '',
        'Devolvido por:/ Entregue para:': entry.to_from,
        'Nome': entry.to_from_fullName,
        'Função': entry.to_from_function,
        'Status Anterior': entry.status_before
      }));
    
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "History");
    
      XLSX.writeFile(workbook, `${equipment.code}_history.xlsx`);
    };
  
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{equipment.code}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">Tipo: {equipment.type}</Typography>
            <Typography variant="body1">Modelo: {equipment.model}</Typography>
            <Typography variant="body1">Fabricante: {equipment.manufacturer}</Typography>
            <Typography variant="body1">Número de Série: {equipment.serialNumber}</Typography>
            <Typography variant="body1">Patrimônio: {equipment.companyNumber}</Typography>
            <Typography variant="body1">Departamento: {equipment.department}</Typography>
  
            <Divider sx={{ marginY: 2 }} />
  
            <Typography variant="h6">Histórico</Typography>
            <Divider sx={{ marginY: 1 }} />
            <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
              {sortedHistory.length === 0 ? (
                <Typography>Nenhum histórico disponível.</Typography>
              ) : (
                sortedHistory.map((entry, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <Typography variant="body2" >
                      Data do registro: {new Date(entry.date).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" >
                      Status do Equipamento: {entry.action}
                    </Typography>
                    <Typography variant="body2" >
                      Último uso por: {entry.to_from} - {entry.to_from_fullName || "Desconhecido"} ({entry.to_from_function || "Função não informada"})
                    </Typography>
                    {entry.observations && (
                      <Typography variant="body2" >
                        Observações: {entry.observations}
                      </Typography>
                    )}
                    <Typography variant="body2" >
                      Fotos:
                    </Typography>
                    {entry.photos && entry.photos.length > 0 && (
                      <Box sx={{ marginTop: 1 }}>
                        {entry.photos.map((photo, photoIndex) => (
                          <img
                            key={photoIndex}
                            src={photo}
                            alt={`Histórico do Equipamento ${photoIndex}`}
                            style={{ maxWidth: '100%', maxHeight: 100, marginBottom: 10, cursor: 'pointer' }}
                            onClick={() => handleImageClick(photo)}
                          />
                        ))}
                      </Box>
                    )}
                    
                    <Typography variant="body2" >
                      Responsável: {entry.user}
                    </Typography>
                    <Divider sx={{ marginTop: 1 }} />
                  </Box>
                ))
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={exportToExcel} sx={{backgroundColor:'#00002E', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus >Exportar para Excel</Button>
            <Button onClick={handleClose} sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus>Fechar</Button>
          </DialogActions>
        </Dialog>
  
        <Dialog open={imageModalOpen} onClose={handleImageModalClose}>
          <DialogContent>
            {selectedImage && (
              <img src={selectedImage} alt="Imagem ampliada" style={{ width: '100%' }} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleImageModalClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleAssignmentClick = () => {
    if (selectedRows.length < 1) {
      // Abrir o diálogo de confirmação para exportação
      setExportConfirmOpen(true);
    } else {
      const selectedEquipment = equipments.find(
        (equipment) => equipment.id === selectedRows[0]
      );
      setEquipmentDetails(selectedEquipment);
      setDetailsModalOpen(true);
    }
  };
  
  // Função para exportar os dados após a confirmação
  const handleExportConfirm = () => {
    const data = filteredRows.map((equipment) => ({
      Código: equipment.code,
      Equipamento: equipment.equipment,
      Status: equipment.status === 0 ? 'Disponível' : equipment.status === 1 ? 'Em Uso' : equipment.status === 66 ? 'Danificado' : 'Inativo',
      'Último Registro': equipment.lastRegistry,
      'Usado Por': equipment.usedBy,
      Setor: equipment.department,
      'Número de Série': equipment.serialnumber,
      Patrimônio: equipment.companynumber,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventário');
    XLSX.writeFile(workbook, 'inventario_equipamentos.xlsx');
  
    setAlertOpen(true);
    setAlertMessage("Lista de equipamentos exportada com sucesso!");
    setAlertSeverity('success');
    setExportConfirmOpen(false); // Fechar o diálogo de confirmação
  };
  
  // Diálogo de confirmação para exportação
  const ExportConfirmDialog = ({ open, onClose, onConfirm }) => (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="export-confirm-dialog-title"
      aria-describedby="export-confirm-dialog-description"
    >
      <DialogTitle id="export-confirm-dialog-title">
        Exportar Invetário
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="export-confirm-dialog-title" sx={{marginBottom:'10px'}}>
          Deseja realmente exportar o Inventário?
        </DialogContentText>
        <DialogContentText id="export-confirm-dialog-description" sx={{fontSize:'12px'}}>
          <strong>Nota:</strong> Se deseja visualizar o histórico de um equipamento específico, selecione-o na lista antes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>
          Cancelar
        </Button>
        <Button onClick={onConfirm} sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
  

  const handleQRCodeClick = (equipment) => {
    setQrCodeEquipment(equipment);
    setQrCodeModalOpen(true);
  };

  const QRCodeDialog = ({ open, handleClose, equipment }) => {
    const qrRef = React.createRef(); // To reference the QRCode for downloading
  
    const handleDownload = () => {
      const qrCanvas = qrRef.current.querySelector('canvas');
      const pngUrl = qrCanvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
  
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${equipment.code}-qrcode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
  
    const handlePrint = () => {
      const qrCanvas = qrRef.current.querySelector('canvas');
      const qrWindow = window.open('');
      qrWindow.document.write('<html><head><title>Print QRCode</title></head><body>');
      qrWindow.document.write(`<img src="${qrCanvas.toDataURL()}" />`);
      qrWindow.document.write('</body></html>');
      qrWindow.document.close();
      setTimeout(() => {
        qrWindow.focus();
        qrWindow.print();
      }, 500); // Delay to ensure image is rendered before print
    };
  
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{ m: 0, p: 2, textAlign:'center'}}>
          <strong>QR Code</strong>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 5,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {equipment ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 2
              }}
            >
              <Box ref={qrRef}>
                <QRCodeCanvas value={equipment.code} size={300} fgColor="#2e3436" level="M"/>
              </Box>
              <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1}}>
                {equipment.code}
              </Typography>
            </Box>
          ) : (
            <Typography>Nenhum equipamento selecionado</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Printicon />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

const handleConfirmInactive = async () => {
  if (inactiveEquipment) {
    await setEquipmentInactive(inactiveEquipment); // Call the function to update status
  }
  setModalInactiveOpen(false); // Close the modal
};

const handleCloseInactive = () => {
  setModalInactiveOpen(false); // Close the modal without doing anything
};

const setEquipmentInactive = async (equipment) => {
  try {
    const response = await fetch("https://serverwinglet.winglet.app/api/update_equipment", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        id: equipment.id,
        equipment: equipment.code,
        status: 99,  // Mark as inactive
        user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`,
        funcao: user?.userInfo?.user_companyfunction,
        badge: 'N/A',
        employeeName: "N/A",
        observations: 'Equipamento inativado',
        photos: [], // URLs or empty if not applicable
      }),
      headers: {
        Authorization: `${sessionStorage.getItem('accessToken')}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setAlertOpen(true);
      setAlertMessage(`Operação realizada com sucesso para o equipamento ${equipment.code}`);
      setAlertSeverity('success');
    } else {
      setAlertOpen(true);
      setAlertMessage("Ops! Algo deu errado. Tente novamente");
      setAlertSeverity('warning');
    }
  } catch (error) {
    setAlertOpen(true);
    setAlertMessage(`Ops! Não foi possível completar a operação. Erro: ${error.message}`);
    setAlertSeverity('error');
  }
};

// Ensure the ConfirmDialogInactive component is correctly used
const ConfirmDialogInactive = ({ open, handleCloseInactive, handleConfirmInactive, equipment }) => (
  <Dialog
    open={open}
    onClose={handleCloseInactive}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Confirmar Atualização de Status"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Tem certeza de que deseja Inativar o equipamento{" "}
        <strong>{equipment?.code}</strong>?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseInactive} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>
        Cancelar
      </Button>
      <Button
        onClick={handleConfirmInactive}
        sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus
      >
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

const handleDeleteClick = (equipment) => {
  setEquipmentToDelete(equipment);
  setModalDeleteOpen(true);
};

// Update the delete confirmation handler
const handleConfirmDelete = async () => {
  if (equipmentToDelete) {
    await setEquipmentDelete(equipmentToDelete);
  }
  setModalDeleteOpen(false);
  setEquipmentToDelete(null);
};

const handleCloseDelete = () => {
  setModalDeleteOpen(false);
  setEquipmentToDelete(null);
};

const setEquipmentDelete = async (equipment) => {
  try {
    const response = await fetch("https://serverwinglet.winglet.app/api/update_equipment", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        id: equipment.id,
        equipment: equipment.code,
        status: 77,  // Mark as inactive
        user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`,
        funcao: user?.userInfo?.user_companyfunction,
        badge: 'N/A',
        employeeName: "N/A",
        observations: 'Equipamento removido',
        photos: [], // URLs or empty if not applicable
      }),
      headers: {
        Authorization: `${sessionStorage.getItem('accessToken')}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setAlertOpen(true);
      setAlertMessage(`Operação realizada com sucesso para o equipamento ${equipment.code}`);
      setAlertSeverity('success');
    } else {
      setAlertOpen(true);
      setAlertMessage("Ops! Algo deu errado. Tente novamente");
      setAlertSeverity('warning');
    }
  } catch (error) {
    setAlertOpen(true);
    setAlertMessage(`Ops! Não foi possível completar a operação. Erro: ${error.message}`);
    setAlertSeverity('error');
  }
};

// Ensure the ConfirmDialogInactive component is correctly used
const ConfirmDialogDelete = ({ open, handleCloseDelete, handleConfirmDelete, equipment }) => {
  const [confirmText, setConfirmText] = useState('');
  const isConfirmEnabled = confirmText === 'Confirmar remoção';

  return (
    <Dialog
      open={open}
      onClose={handleCloseDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirmar a remoção do equipamento?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem certeza de que deseja <strong>REMOVER</strong> o equipamento{" "}
          <strong>{equipment?.code}</strong>?
        </DialogContentText>
        
        <Box 
          sx={{ 
            backgroundColor: '#ffebee', 
            color: '#c62828',
            p: 2,
            mt: 2,
            mb: 2,
            borderRadius: '4px',
            border: '1px solid #ef9a9a'
          }}
        >
          <Typography variant="body2">
            ATENÇÃO: A remoção de equipamentos é irreversível. Ao remover, o equipamento e seu histórico serão removidos. Você tem certeza que deseja continuar?
          </Typography>
        </Box>

        <TextField
          fullWidth
          margin="dense"
          label="Digite 'Confirmar remoção' para continuar"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          error={confirmText !== '' && !isConfirmEnabled}
          helperText={confirmText !== '' && !isConfirmEnabled ? "Texto não corresponde" : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleCloseDelete} 
          sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmDelete}
          disabled={!isConfirmEnabled}
          sx={{
            backgroundColor: isConfirmEnabled ? 'rgba(40, 141, 9, 1)' : '#ccc',
            color:'#FFF', 
            cursor: isConfirmEnabled ? 'pointer' : 'not-allowed',
            borderRadius:'6px'
          }} 
          autoFocus
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const handleLeituraDigitalClick = () => {
  const areaSection = sessionStorage.getItem('area_section');
  if(areaSection !== 'null') {
  navigate(`/modules/${areaSection}/scan`);
} else {
  navigate(`/modules/ceop/scan`);
}
};

// Add confirmation dialog component
const BulkConfirmDialog = ({ open, onClose, actionType, count }) => {
  let title = '';
  let message = '';
  
  switch (actionType) {
    case 'available':
      title = 'Disponibilizar Equipamentos';
      message = `Deseja disponibilizar ${count} equipamentos selecionados?`;
      break;
    case 'inactive':
      title = 'Inativar Equipamentos';
      message = `Deseja inativar ${count} equipamentos selecionados?`;
      break;
    case 'delete':
      title = 'Deletar Equipamentos';
      message = `ATENÇÃO: Você está prestes a deletar ${count} equipamentos. Esta ação é irreversível.`;
      break;
    default:
      return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E'}}>
          Cancelar
        </Button>
        <Button 
          onClick={() => {
            switch (actionType) {
              case 'available':
                handleBulkAvailable();
                break;
              case 'inactive':
                handleBulkInactive();
                break;
              case 'delete':
                handleBulkDelete();
                break;
            }
            onClose();
            setSpeedDialOpen(true);
          }}
          sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF'}}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};


  return (
<>
      <ConfirmDialog
      open={modalOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      equipment={selectedEquipment} // Pass the selected equipment to the modal
    />
    <EditEquipmentDialog
    open={editModalOpen}
    handleClose={() => setEditModalOpen(false)}
    equipment={editEquipment}  // Pass the selected equipment for editing
    handleSave={handleSaveEdit}  // Pass the save handler
  />
  <EquipmentDetailsDialog
    open={detailsModalOpen}
    handleClose={() => setDetailsModalOpen(false)}
    equipment={equipmentDetails}
  />
    <QRCodeDialog
    open={qrCodeModalOpen}
    handleClose={() => setQrCodeModalOpen(false)}
    equipment={qrCodeEquipment} // Equipamento selecionado
  />
    <ConfirmDialogInactive
      open={modalInactiveOpen}
      handleCloseInactive={handleCloseInactive}
      handleConfirmInactive={handleConfirmInactive}
      equipment={inactiveEquipment}
    />
    <ExportConfirmDialog
      open={exportConfirmOpen}
      onClose={() => setExportConfirmOpen(false)}
      onConfirm={handleExportConfirm}
    />
<ConfirmDialogDelete
  open={modalDeleteOpen}
  handleCloseDelete={handleCloseDelete}
  handleConfirmDelete={handleConfirmDelete}
  equipment={equipmentToDelete}
/>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", justifyContent: "space-evenly", width: "100%", flexGrow: 1 }}
      >
                   <Box sx={{
            flexGrow: 0,
            backgroundColor: "#E0E0E0",
            display: "flex",
            flexDirection: "row",
            position: "relative",
            
          }}>
            {selectedRows.length > 1 && (
  <SpeedDial
    ariaLabel="Ações em lote"
    sx={{
      position: 'fixed',
      bottom: 16,
      right: 16,
    }}
    icon={<SpeedDialIcon />}
    open={speedDialOpen}
    onOpen={() => setSpeedDialOpen(true)}
    onClose={() => setSpeedDialOpen(false)}
  >
<SpeedDialAction
  icon={<AvailableIcon />}
  tooltipTitle="Disponibilizar selecionados"
  tooltipOpen
  sx={{ 
    "& .MuiSpeedDialAction-staticTooltipLabel": {
      whiteSpace: "nowrap"
    }
  }}
  onClick={() => {
    setBulkActionType('available');
    setBulkConfirmOpen(true);
    setSpeedDialOpen(false);
  }}
/>
<SpeedDialAction
  icon={<InactiveIcon />}
  tooltipTitle="Inativar selecionados" 
  tooltipOpen
  sx={{
    "& .MuiSpeedDialAction-staticTooltipLabel": {
      whiteSpace: "nowrap"
    }
  }}
  onClick={() => {
    setBulkActionType('inactive');
    setBulkConfirmOpen(true);
    setSpeedDialOpen(false);
  }}
/>
<SpeedDialAction
  icon={<DeleteIcon />}
  tooltipTitle="Deletar selecionados"
  tooltipOpen
  sx={{
    "& .MuiSpeedDialAction-staticTooltipLabel": {
      whiteSpace: "nowrap"
    }
  }}
  onClick={() => {
    setBulkActionType('delete'); 
    setBulkConfirmOpen(true);
    setSpeedDialOpen(false);
  }}
/>

  </SpeedDial>
)}
<BulkConfirmDialog
  open={bulkConfirmOpen}
  onClose={() => {
    setBulkConfirmOpen(false);
    setBulkActionType(null);
  }}
  actionType={bulkActionType}
  count={selectedRows.length}
/>
        <Snackbar
            open={alertOpen}
            autoHideDuration={3000}
            onClose={() => setAlertOpen(false)} // Fechar automaticamente após a duração
            onClick={() => setAlertOpen(false)}
            onKeyDown={() => setAlertOpen(false)}  
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              position: "absolute",
              display: "flex",
              width: 'fit-content',
              mt: '50px',
              mr: '45px',
              zIndex: 9999, // Ensure Snackbar appears above other elements
            }}
          >
            <Alert
              variant="filled"
              severity={alertSeverity}
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                
              }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
          </Box>
        <CssBaseline />
        <DefaultLayout title='Controle de Equipamentos'>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#E0E0E0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            sx={{  mt: 0, backgroundColor: "transparent", display:'flex', flexGrow: 1, flexDirection:'column', minWidth: '100%', justifyContent:'stretch', mr: 0, ml: 0}}
          >
            <Typography variant="h5" gutterBottom sx={{ color: "#00002E" }}>
              Resumo
            </Typography>
            <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          columns={6}
          
          sx={{ color: "#00002E", justifyContent: "space-evenly"}}
        >
          {[
            { title: "Total", value: cardValues.total.value, change: cardValues.total.change },
            { title: "Disponíveis", value: cardValues.available.value, change: cardValues.available.change },
            { title: "Em uso", value: cardValues.inUse.value, change: cardValues.inUse.change },
            { title: "Inativos", value: cardValues.lost.value, change: cardValues.lost.change },
            { title: "Manutenção", value: cardValues.maintenance.value, change: cardValues.maintenance.change },
            { title: "+12h", value: cardValues.overdue12h.value, change: cardValues.overdue12h.change },
            { title: "+24h", value: cardValues.overdue24h.value, change: cardValues.overdue24h.change },
          ].map((card, index) => (
            <React.Fragment key={index}>
              <Grid item sx={{justifyContent:'stretch', display:'flex', flexGrow:1}}>
                <StatCard
                  title={card.title}
                  value={card.value}
                  change={card.change}
                />
              </Grid>
              {index <= 5 && (
                <Grid item sx={{justifyContent:'stretch', display:'flex', flexGrow:1}} >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      bgcolor: "#CCCCCC",
                      height: "60px",
                      alignSelf: "center",
                      justifyContent: "center",
                      mx: 0.5,
                      my: 0.5,
                      color: "#00002E",
                      display: { xs: "none", md: "block" },
                    }}
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
       
            <Box
              sx={{
                mt: 5,
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                paddingY: 0.5,
                paddingRight: 2,
                width: {lg: "fit-content", md: '100%'},
                height: "fit-content",
                color: "#00002E",
                overflow: "auto",
                maxWidth: "90%",
                minWidth: "50%",
                flexDirection: "row",
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                textColor="primary"
                variant="scrollable"
        scrollButtons
        
                TabIndicatorProps={{
                  sx: {
                    display: "none",
                    color: "#00002E",
                  },
                }}
              >
                <Tab
                  label="Inventário"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
                <Tab
                  label="Disponível"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002e",
                      borderRadius: "10px",
                    },
                  }}
                />
                <Tab
                  label="Em Uso"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
                <Tab
                  label="Manutenção"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
                <Tab
                  label="Inativos"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
                <Tab
                  label="+12h"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
                                <Tab
                  label="+24h"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Tabs>
              <Box sx={{ ml: 2, display: "flex", gap: 2 }}>
              <Button
  variant="contained"
  color="success"
  startIcon={<AddCircleIcon />}
  sx={{
    textTransform: "none",
    backgroundColor: "#A5D6A7",
    border: "1px solid #007C39",
    color: "#007C39",
    borderRadius: "10px",
    maxHeight: "60px",
    textWrap: "wrap",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    '@media (max-width: 1460px)': {
      '.MuiButton-startIcon': {
        justifyContent: 'space-between',
        size: 'small',
        ml: 1,
        
      },
      '#text': {
        display: 'none',
      },
    },
  }}
  onClick={handleAddItemClick}
  disabled={user?.userInfo?.user_accesslevel.level === "User"}
>
  <Typography id="text">Adicionar Itens</Typography>
</Button>
                <Menu anchorEl={addItemAnchorEl} open={Boolean(addItemAnchorEl)} onClose={handleAddItemClose}>
                    <MenuItem onClick={handleAddEquipmentClick}>Adicionar Equipamento</MenuItem>
                    <MenuItem onClick={handleAddOtherItemsClick}>Adicionar Outros (tipo, modelo...)</MenuItem>
                  </Menu>
      <AddItemModal
        open={addItemModalOpen}
        onClose={() => setAddItemModalOpen(false)}
        onSuccess={handleAddSuccess}
      />
                    <AddEquipmentModal
                open={addEquipmentModalOpen}
                onClose={() => setAddEquipmentModalOpen(false)}
                onSuccess={handleAddSuccess}
              />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<QrCodeScannerIcon />}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#CE93D8",
                    border: "1px solid #00002E",
                    color: "#00002E",
                    borderRadius: "10px",
                    maxHeight: "60px",
                    textWrap: "wrap",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    '@media (max-width: 1460px)': {
      '.MuiButton-startIcon': {
        justifyContent: 'space-between',
        size: 'small',
        ml: 1,
        
      },
      '#text': {
        display: 'none',
      },
    },
                  }}
                  onClick={handleLeituraDigitalClick}
                >
  <Typography id="text">Leitura Digital</Typography>
  </Button>
                <Box
              sx={{
                position: {md: "fixed", xs: 'fixed', lg: 'absolute'},
                right: {md: "50px", xs: '50px', lg: '40px'},
                display: {md: "flex", xs: 'flex'},
                gap: 0.5,
                flexDirection: 'row',
                top: { xs: "100px", md: "80px", lg: "250px" },
                alignItems: { xs: "center", md: "center" },
                zIndex: "1600",
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  <IconButton
                    size="large"
                    sx={{
                      color: "#00002E",
                      backgroundColor: "rgba(238, 238, 238, 0.35)",
                      borderRadius: "20%",
                      mb: { xs: 1, md: 0 },
                    }}
                    onClick={handleSearchClick}
                  >
                    <SearchIcon fontSize="medium" />
                  </IconButton>
                  <Popper
                    open={Boolean(searchAnchorEl)}
                    anchorEl={searchAnchorEl}
                    onClose={handleSearchClose}
                    placement="bottom-start"
                    disablePortal
                    sx={{ zIndex: 1600 }}
                  >
                    {searchOpen && (
                      <Box
                        sx={{
                          mt: 1,
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                          boxShadow: 3,
                          width: "600px",
                          padding: "8px",
                        }}
                      >
                        <TextField
                          size="small"
                          variant="outlined"
                          placeholder="Buscar..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </Box>
                    )}
                  </Popper>
                </Box>
                </ClickAwayListener>
                    
              <IconButton
                size="large"
                onClick={handleAssignmentClick}
                sx={{
                  color: "#00002E",
                  backgroundColor: "rgba(238, 238, 238, 0.35)",
                  borderRadius: "20%",
                }}
              >
                <AssignmentIcon fontSize="medium" />
              </IconButton>
              <IconButton
                    size="large"
                    sx={{
                      color: "#00002E",
                      backgroundColor: "rgba(238, 238, 238, 0.35)",
                      borderRadius: "20%",
                      mb: { xs: 1, md: 0 }
                }}
                disabled

              >
                <SettingsIcon fontSize="medium" />
              </IconButton>
            </Box>
              </Box>
            </Box>
          </Container>
          <Box sx={{ width: '100%', mt: 2 }}>
      </Box>
      <DataGrid
  paginationMode="client"
  pagination={true}
  autosizeOnMount={false}
  autosizeOptions={{
    columns: ['status', 'code', 'equipment', 'usedBy', 'lastRegistry', 'department', 'serialnumber', 'companynumber', 'actions'],
    includeOutliers: true,
    includeHeaders: true,
  }}
  initialState={{
    pagination: { paginationModel: { pageSize: 25 } },
    sorting: { sortModel: [{ field: 'lastRegistry', sort: 'desc' }] },
  }}
  pageSizeOptions={[10, 25, 50, 100, { value: -1, label: 'Todos' }]}
  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
  rows={filteredRows || []}
  columns={columns}
  checkboxSelection
  disableColumnResize={false}
  disableRowSelectionOnClick
  onRowSelectionModelChange={handleRowSelection}
  sx={{
    mt: 3,
    ml: '20px',
    mr: '20px',
    zIndex: 999,
    backgroundColor: "transparent",
    borderRadius: "10px",
    height: "100%",
    ".MuiDataGrid-columnHeaders": {
      backgroundColor: "#FFFFFF",
    },
    ".MuiDataGrid-row": {
      borderBottom: "1px solid #B0BEC5",
    },
  }}
/>
        </Box>
        </DefaultLayout>
      </Box>

    </>
  );
};

export default CEOPEquipmentDashboard;
