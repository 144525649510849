import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
  Avatar,
  Alert, 
  Snackbar,
} from '@mui/material';
import '../../../App.css';
import { UserContext } from '../../../contexts/UserContext';

const EditProfileModal = ({ open, onClose, onSave, onSuccess }) => {

    
    const { user } = useContext(UserContext);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

 
  useEffect(() => {
    alertOpen && setTimeout(() => setAlertOpen(false), 3000);
  });
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (formData.newPassword !== formData.confirmPassword) {
      setAlertOpen(true);
        setAlertMessage('As senhas não coincidem');
        setAlertSeverity('error');
      return;
    }
    if (formData.newPassword.length > 8 || formData.confirmPassword.length < 8) {
        setAlertOpen(true);
            setAlertMessage('A senha deve ter no mínimo 8 caracteres');
            setAlertSeverity('error');
        return;
    }
    onSave(formData);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ backgroundColor: '#f0f0f0', padding: 2, display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={formData.profilePicture}
          alt="Foto de perfil"
          sx={{ width: 64, height: 64, mr: 2 }}
        />
        <Box>
          <Typography variant="h6">{user?.userInfo?.user_firstname + " " + user?.userInfo?.user_surname}</Typography>
          <Typography variant="body2">{user?.userInfo?.user_badge}</Typography>
          <Typography variant="body2">{user?.userInfo?.user_companyfunction}</Typography>
        </Box>
      </Box>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          Meus dados:
        </Typography>
        <TextField
          label="Senha atual"
          name="currentPassword"
          type="password"
          fullWidth
          value={formData.currentPassword}
          onChange={handleInputChange}
          margin="dense"
        />
        <TextField
          label="Senha nova"
          name="newPassword"
          type="password"
          fullWidth
          value={formData.newPassword}
          onChange={handleInputChange}
          margin="dense"
          error={formData.newPassword.length < 8 || formData.newPassword !== formData.confirmPassword} 
        
        />
        <TextField
          label="Confirme sua nova senha"
          name="confirmPassword"
          type="password"
          fullWidth
          value={formData.confirmPassword}
          onChange={handleInputChange}
          margin="dense"
          error={formData.confirmPassword.length < 8 || formData.newPassword !== formData.confirmPassword} 

        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', padding: 2 }}>
        <Button onClick={onClose} variant="contained" sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>
          Cancelar
        </Button>
        <Button onClick={handleSave} color="success" variant="contained" disabled={formData.newPassword !== formData.confirmPassword || formData.confirmPassword === '' || formData.confirmPassword === '' || formData.confirmPassword.length < 8 || formData.newPassword.length < 8}>
          Confirmar
        </Button>
      </DialogActions>
      <Snackbar
                    open={alertOpen}
                    autoHideDuration={5000}
                    onClose={() => setAlertOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{ width: 'inherit', mr: 10, marginBlockStart:-2.5, overflow:'hidden', display:'flex', position:'absolute' }} // Adjust width and margin as needed
                  >
                    <Alert
                      variant="filled"
                      onClose={() => setAlertOpen(false)}
                      severity={alertSeverity}
                      onClick={() => setAlertOpen(false)}
                      sx={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip'}}
                    >
                      {alertMessage}
                    </Alert>
                  </Snackbar>
    </Dialog>
  );
};

export default EditProfileModal;