import React from 'react';
import logoImage from '../assets/images/winglet_logo.png'; // Adjust the path as needed

const WingletLogo = ({ width = 200, height = 'auto' }) => (
  <img
    src={logoImage}
    alt="Winglet Logo"
    style={{
      width: width,
      height: height,
      maxWidth: '100%',
      objectFit: 'contain'
    }}
  />
);

export default WingletLogo;