import React, { useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import PersistentDrawer from '../components/Drawer';
import useAuth from '../hooks/useAuth';
import useUser from '../hooks/useUser';
// Imports from Material-UI
import { Grid, Paper, Typography, Box, Container, Avatar, Menu, MenuItem, IconButton, Alert, Snackbar, ListItemText, ListItemIcon, Divider, CircularProgress } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';

// Import assets and icons
import WingletLogo from '../components/WingletLogo'; 
import folderImage from '../assets/images/folder.png'; 
import PoweredLabel from '../components/PoweredLabel';
import ceopIcon from '../assets/icons/ceop_icon.png';
import lockersIcon from '../assets/icons/lockers_icon.png';
import skyGuardianIcon from '../assets/icons/skyguardian_icon.png';
import voosIcon from '../assets/icons/flights_icon.png';
import coiIcon from '../assets/icons/coi_icon.png';
import gseIcon from '../assets/icons/gse_icon.png';
import pnaeIcon from '../assets/icons/pnae_icon.png';
import EditProfileModal from '../components/sections/users/EditProfileModal';

// Styled component for badge positioning
const StyledBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

// Mapping of categories to their respective images
const moduleImages = {
  'CEOP': ceopIcon,
  'LOCKERS': lockersIcon,
  'SKY GUARDIAN': skyGuardianIcon,
  'VOOS': voosIcon,
  'PNAE': pnaeIcon,
  'COI': coiIcon,
  'GSE': gseIcon,
  // Add more categories and their images here
};

const ModuleSelection = () => {
  useAuth();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [isSuperAdminState, setIsSuperAdminState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const userBadge = sessionStorage.getItem('user_badge');
  const fetchedUser = useUser(userBadge);

  
  // State for managing drawer open/close
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Navigation function from react-router-dom
  const navigate = useNavigate();

  // Effect to show welcome alert when user info is available
  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
    if (user && user.userInfo) {
      setLoading(false);
      setAlertOpen(true);
      setAlertMessage(`Olá, ${user.userInfo.user_firstname}! Bem-vindo de volta. 😊`);
      setAlertSeverity('success');
      if (user?.userInfo?.user_accesslevel.level === 'superAdmin') {
        setIsSuperAdminState(true);
      }
    }
  }, [user, fetchedUser]); // Only run when user info changes

  // State for managing avatar menu anchor element
  const [anchorEl, setAnchorEl] = useState(null);

  // Handle avatar click to open menu
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the avatar menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Toggle drawer open/close
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle user logout
  const handleLogout = async () => {
  
      // Remove the access token from session storage
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("area_section");
  
    // Invalidate the refresh token on the server
  await fetch('https://serverwinglet.winglet.app/api/logout', {
    method: 'POST',
    credentials: 'include', // Ensure cookies are included
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ badge: sessionStorage.getItem("user_badge") }),
  });

  // Remove the refresh token cookie
  Cookies.remove('refreshToken');
  sessionStorage.removeItem("user_badge");
  
  // Redirect to the login page
  navigate('/');

  };

  // Define the click handler function
  const handleCategoryClick = (category) => {
    switch (category) {
      case 'CEOP':
        navigate('/modules/ceop/dashboard');  
      //window.location.href = 'https://ceop.winglet.app/dashboard';
        break;
      case 'LOCKERS':
        navigate('/lockers');
        break;
      case 'SKY GUARDIAN':
        navigate('/skyguardian');
        break;
      case 'VOOS':
        navigate('/voos');
        break;
      case 'PNAE':
        navigate('/pnae');
        break;
      case 'COI':
        navigate('/coi');
        break;
      case 'GSE':
        navigate('/gse');
        break;
      // Add more cases for other categories
      default:
        navigate('/');
        break;
    }
  };

  // Get user modules and access level
  const categories = user?.userInfo?.user_modulesallowed?.modules || [];

  // Handle click to add an equipment
const handleConfigClick = () => {
  setEditProfileModalOpen(true);
};

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', overflow:'hidden' }}>
      {/* Persistent drawer component */}
      <PersistentDrawer isSuperAdmin={isSuperAdminState} open={drawerOpen} onClose={toggleDrawer} handleLogout={handleLogout} userModules={categories}/>
      <Box
        component="main"
        sx={{ 
          flexGrow: 1, 
          p: 8, 
          transition: (theme) => theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }), 
          marginLeft: drawerOpen ? 0 : 0 
        }}
      >
        <Container 
          maxWidth="md" 
          sx={{ 
            maxHeight: '100hv', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-around',
            py: 12, // Add vertical padding
            position: 'relative',
            overflowY:'hidden',
            marginBottom: '-10px'
          }}
        >
          {/* Avatar menu and alert */}
          <StyledBadge>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                  <IconButton onClick={handleAvatarClick}>
                    <Avatar alt={user?.userInfo?.user_firstname} src="/static/images/avatar/1.jpg" />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    {/* Menu items */}
                    <MenuItem>
                      <Typography variant='button' sx={{ textTransform: 'capitalize', marginRight: '5px', fontWeight:'bold'}}>
                        {user?.userInfo?.user_firstname}
                      </Typography>
                      <Typography variant='button' sx={{ textTransform: 'capitalize', fontWeight:'bold'}}>
                        {user?.userInfo?.user_surname}
                      </Typography>
                    </MenuItem>
                    <MenuItem disableTouchRipple='true'>
                      <Typography variant='caption' sx={{marginTop: '-10px', fontSize:'12px'}}>
                        {user?.userInfo?.user_badge}
                      </Typography>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleConfigClick}> 
                      <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Configurações</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Sair</ListItemText>
                    </MenuItem>
                  </Menu>
                </Grid>
                {/* Snackbar for alerts */}
                <Grid item xs={6}>
                  <Snackbar
                    open={alertOpen}
                    autoHideDuration={5000}
                    onClose={() => setAlertOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{ width: 'inherit', mr: 10, marginBlockStart:-2.5, overflow:'hidden', display:'flex', position:'absolute' }} // Adjust width and margin as needed
                  >
                    <Alert
                      variant="filled"
                      onClose={() => setAlertOpen(false)}
                      severity={alertSeverity}
                      onClick={() => setAlertOpen(false)}
                      sx={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip'}}
                    >
                      {alertMessage}
                    </Alert>
                  </Snackbar>
                </Grid>
              </Grid>
            </Box>
            <EditProfileModal
                open={editProfileModalOpen}
                onClose={() => setEditProfileModalOpen(false)}
              />
          </StyledBadge>
          
          {/* Winglet logo */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <WingletLogo width={200} />
          </Box>
          
          {/* Module selection prompt */}
          <Typography variant="subtitle1" align="center" gutterBottom sx={{ mb: 6 }}>
            Selecione o módulo que deseja acessar:
          </Typography>
          
          {/* Grid of modules */}
          <Grid container spacing={3} justifyContent="center" sx={{ mb: 2 }}>
            {categories.map((category, index) => (
              <Grid item xs={12} sm={4} key={category} sx={{ mb: index < 3 ? 0 : 0 }}>
                <Paper
                  elevation={6}
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    height: 120, 
                    backgroundColor:'#E0E0E0',
                    color: '#01579B', // Fixed height for uniformity
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                  onClick={() => handleCategoryClick(category)} // Pass the click handler
                >
                  <Box
                    component="img"
                    src={moduleImages[category] || folderImage}
                    alt={category}
                    sx={{ width: 36, height: 42, mb: 1 }}
                  />                
                  <Typography variant="body2" align="center">
                    {category}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          
          {/* Powered by label */}
          <PoweredLabel width={150}/>
          <Typography variant="caption" align="center" marginBlock={'50px'} sx={{color:'#E0E0E0'}}> v 0.0.1 - Beta | Depto. de Inovação e Novas Tecnologias 👾</Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default ModuleSelection;