import React from 'react';
import smallLogo from '../assets/images/winglet_logo_small.png'; // Adjust the path as needed

const WingletLogoSmall = ({ width = 200, height = 'auto' }) => (
  <img
    src={smallLogo}
    alt="Winglet Logo"
    style={{
      width: width,
      height: height,
      maxWidth: '100%',
      objectFit: 'contain',
      borderRadius: '15%',
      marginLeft: {xs: '10px', sm: '20px'},
    }}
  />
);

export default WingletLogoSmall;