import { useState } from 'react';

const useUpdatePassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const token = sessionStorage.getItem('accessToken');



  const updatePassword = async (formData) => {
    //setLoading(true);
    //setError(null);
    //setSuccess(false);
    const userBadge = sessionStorage.getItem('user_badge')
    console.log(formData); // Corrigido para acessar user_areasection de forma segura


    try {

      const response = await fetch(`https://serverwinglet.winglet.app/api/update_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify({formData, userBadge}),
      });

      if (response.ok) {
        setSuccess(true);

      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Falha ao adicionar equipamento');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { updatePassword, success, error, loading };
};

export default useUpdatePassword;
