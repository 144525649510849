// winglet/src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import useUser from '../hooks/useUser';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const userBadge = sessionStorage.getItem('user_badge');
  const fetchedUser = useUser(userBadge);

  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
  }, [fetchedUser]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};