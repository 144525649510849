import { useState, useEffect } from 'react';

const useUser = (userBadge) => {
  const [userInfo, setUser] = useState(null);
  const token = sessionStorage.getItem('accessToken');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`https://serverwinglet.winglet.app/api/users`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`, // Include the token in the Authorization header
          },
          body: JSON.stringify({ userBadge }),
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (userBadge && token) {
      fetchUser();
    }
  }, [userBadge, token]);

  return userInfo;
};

export default useUser;