import { useState } from 'react';

const useAddEquipmentType = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const token = sessionStorage.getItem('accessToken');

  const addItem = async (itemType, value) => {
    setLoading(true);
    setError(null);
    setSuccess(false);


    let endpoint = '';
    switch (itemType) {
      case 'type':
        endpoint = '/add_equipment_type';
        break;
      case 'model':
        endpoint = '/add_equipment_model';
        break;
      case 'manufacturer':
        endpoint = '/add_equipment_manufacturer';
        break;
      default:
        return;
    }

    try {
      const response = await fetch(`https://serverwinglet.winglet.app/api${endpoint}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify({value}),
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Falha ao adicionar item');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { addItem, loading, error, success };
};

export default useAddEquipmentType;
