import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
// Imports from Material-UI
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  Grid,
  createTheme,
  ThemeProvider,
  Alert,
  Stack,
  Snackbar,
} from "@mui/material";
// Imports of elements and assets
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import WingletLogo from "../components/WingletLogo"; 
import PoweredLabel from "../components/PoweredLabel"; 
import backgroundImage from "../assets/images/airplanewinglet.png";
import ChangePsswordModal from "../components/ChangePasswordModal"; 

// Create a custom theme for Material-UI components
const theme = createTheme({
  palette: {
    primary: {
      main: "#E0E0E0", 
    },
    blue: {
      main: "#0288d1",
    },
  },
});

export default function LoginPage() {
  useAuth();
  const navigate = useNavigate();


  const [badge, setBadge] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [validationErrors, setValidationErrors] = useState({});
  const [loginAttempts, setLoginAttempts] = useState(0);
  const maxAttempts = 3;
  const [editChangePassModalOpen, setChangePassModalOpen] = useState(false);

  useEffect(() => {
    const successMessage = sessionStorage.getItem('successMessage');
    if (successMessage) {
      setAlertMessage(successMessage);
      setAlertSeverity("success");
      setAlertOpen(true);
      sessionStorage.removeItem('successMessage'); // Remover mensagem do localStorage para que não apareça novamente após outro reload
    }
  }, []);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!badge) errors.badge = "Campo obrigatório";
    if (!password) errors.password = "Campo obrigatório";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    handleOpen();
    try {
      const response = await fetch("https://serverwinglet.winglet.app/api/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ badge, password, rememberMe }),
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem("accessToken", data.accessToken);
        sessionStorage.setItem("user_badge", badge);
        sessionStorage.setItem("area_section", data.user.user_areasection);
        if (data.user.user_lastpasswordchange === null) {
          setChangePassModalOpen(true);
          setBadge("");
          setPassword("");
          handleClose();
          return;
        } else {
        const modulesLenght = data.user.user_modulesallowed.modules.length;
        handleClose();
        if (data.user.user_performdelivery === 1) {
          const areasection = data.user.user_areasection
          if (areasection === null ) {
            navigate(`/modules`);
          } else {
            navigate(`/modules/${areasection}/scan`);
          }
          
        } else if (modulesLenght === 1 ) {
        navigate("/modules/" + data.user.user_modulesallowed.modules[0] + "/dashboard")}
        else {
          navigate("/modules");
          handleClose();
        };
      }} else {
        handleClose();
        setPassword("");
        setLoginAttempts((prev) => prev + 1);
        if (response.status === 401) {
          setAlertOpen(true);
          setAlertMessage(
            "Ops! Algo deu errado. Verifique suas credenciais e tente novamente."
          );
          setAlertSeverity("error");
        }
        console.error("Login failed", response);
      }
    } catch (error) {
      handleClose();
      setAlertOpen(true);
      setAlertMessage(
        "Parece que estamos com problemas. Tente novamente mais tarde."
      );
      setAlertSeverity("warning");
      console.error("Error:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", }}>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={0}
          square
          sx={{ height: "100hv", padding: 4 }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WingletLogo width={200} />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 4, minWidth: "50%", padding:2}}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="badge"
                label="Login"
                name="badge"
                autoComplete="badge"
                autoFocus
                value={badge}
                onChange={(e) => setBadge(e.target.value)}
                error={!!validationErrors.badge}
                helperText={validationErrors.badge}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!validationErrors.password}
                helperText={validationErrors.password}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    sx={{
                      color: "#e0e0e0",
                      "&.Mui-checked": {
                        color: "#0288d1",
                      },
                    }}
                  />
                }
                label="Lembrar meu login"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                sx={{
                  color: "#b6b6b3",
                  "&.Mui-checked": {
                    color: "#0288d1",
                  },
                  textAlign: "center",
                  display: "block",
                  marginTop: 2,
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  ":hover": { backgroundColor: "#0288d1", color: "#fff" },
                }}
                disabled={open || loginAttempts >= maxAttempts}
              >
                ENTRAR
              </Button>
              <Backdrop
                sx={(theme) => ({
                  color: "#fff",
                  zIndex: theme.zIndex.drawer + 1,
                })}
                open={open}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Link
                href="#"
                variant="body2"
                sx={{
                  display: "block",
                  textAlign: "center",
                  color: "#0288d1",
                }}
              >
                Esqueci minha senha
              </Link>
            </Box>
            <Snackbar
              open={alertOpen}
              autoHideDuration={5000}
              onClose={() => setAlertOpen(false)}
            >
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert
                  variant="filled"
                  onClose={() => setAlertOpen(false)}
                  severity={alertSeverity}
                >
                  {alertMessage}
                </Alert>
              </Stack>
            </Snackbar>
            <PoweredLabel width={150} />
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
      <ChangePsswordModal open={editChangePassModalOpen} onClose={() => setChangePassModalOpen(false)} />
    </ThemeProvider>
  );
}