import { useState, useEffect } from 'react';

const useAllUsers = () => {
  const [allUsersInfo, setAllUsers] = useState([]);
  const token = sessionStorage.getItem('accessToken');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`https://serverwinglet.winglet.app/api/all-users`, {
          method: "GET", // Corrigi o método de POST para GET, já que estamos buscando dados.
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`, // Use "Bearer" para tokens.
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAllUsers(data.users || []); // Certifique-se de definir sempre como um array.
        } else {
          console.error('Failed to fetch user data');
          setAllUsers([]); // Defina como array vazio em caso de erro.
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setAllUsers([]); // Defina como array vazio em caso de erro.
      }
    };

    if (token) {
      fetchUser();
    }
  }, [token]);

  return allUsersInfo;
};

export default useAllUsers;
