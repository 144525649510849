import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login';
import ModuleSelection from './pages/SystemSelector';
import ProtectedRoute from './controllers/ProtectedRoute';
import PublicRoute from './controllers/PublicRoute';
import ScanQRCode from './pages/modulespages/ceop/ScanPage';
import Dashboard from './pages/modulespages/ceop/Dashboard';
import UsersDashboard from './pages/UsersDashboard';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PublicRoute><LoginPage /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><LoginPage /></PublicRoute>} />
        <Route path="/modules" element={<ProtectedRoute><ModuleSelection /></ProtectedRoute>} />
        <Route path="/modules/ceop/scan" element={<ProtectedRoute><ScanQRCode /></ProtectedRoute>} />
        <Route path="/modules/spax/scan" element={<ProtectedRoute><ScanQRCode /></ProtectedRoute>} />
        <Route path="/modules/ceop/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/modules/spax/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

        <Route path="/usersdashboard" element={<ProtectedRoute><UsersDashboard /></ProtectedRoute>} />

      </Routes>
    </Router>
  );
}

export default App;