import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
} from '@mui/material';
import useAddEquipmentType from '../../hooks/ceop/useAddEquipmentType';

const AddItemModal = ({ open, onClose, onSuccess }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const { addItem, loading, error, success } = useAddEquipmentType();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setInputValue(''); // Limpar o campo de texto ao trocar de aba
  };

  const handleSave = async () => {
    const itemType = tabIndex === 0 ? 'type' : tabIndex === 1 ? 'model' : 'manufacturer';
    await addItem(itemType, inputValue);
  };

  useEffect(() => {
    if (success) {
      setInputValue('');
      onClose();
      onSuccess('Item adicionado com sucesso!');
    }
  }, [success, onClose, onSuccess]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Adicionar Novo Item</DialogTitle>
      <DialogContent>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Tipo" />
          <Tab label="Modelo" />
          <Tab label="Fabricante" />
        </Tabs>
        <TextField
          autoFocus
          margin="dense"
          label={tabIndex === 0 ? 'Tipo de Equipamento' : tabIndex === 1 ? 'Modelo de Equipamento' : 'Fabricante de Equipamento'}
          type="text"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          disabled={loading}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'10px'}} disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleSave} sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus disabled={loading || !inputValue}>
          {loading ? <CircularProgress size={24} /> : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemModal;
