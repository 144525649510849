import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
} from '@mui/material';
import { FilePresentOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import Autocomplete from '@mui/material/Autocomplete';
import useFetchOptions from '../../hooks/ceop/useFetchOptions';
import useAddEquipment from '../../hooks/ceop/useAddEquipment';
import useBulkAddEquipment from '../../hooks/ceop/useBulkAddEquipment';
import '../../App.css'; // Importando o CSS principal

const AddEquipmentModal = ({ open, onClose, onSuccess }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [formData, setFormData] = useState({
    code: '',
    type: '',
    description: '',
    model: '',
    manufacturer: '',
    department: '',
    serialNumber: '',
    companyNumber: '',
  });

  const { types, models, manufacturers, loading } = useFetchOptions();
  const { addEquipment, loading: adding, error, success } = useAddEquipment();
  const { bulkAddEquipment, loading: bulkAdding, error: bulkError, success: bulkSuccess } = useBulkAddEquipment();

  useEffect(() => {
    if (tabIndex === 1) {
      setFile(null);
    }
  }, [tabIndex]);

  const handleCancel = useCallback(() => {
    setFile(null);
    setIsDragging(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (success || bulkSuccess) {
      onSuccess('Equipamento(s) adicionado(s) com sucesso');
      handleCancel();
    }
  }, [success, bulkSuccess, onSuccess, handleCancel]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFile(selectedFile);
    } else {
      alert('Por favor, selecione um arquivo .xlsx válido.');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setFile(droppedFile);
      } else {
        alert('Por favor, selecione um arquivo .xlsx válido.');
      }
      e.dataTransfer.clearData();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (tabIndex === 0) {
      if (file) {
        bulkAddEquipment(file);
      } else {
        alert('Por favor, selecione um arquivo para continuar.');
      }
    } else if (tabIndex === 1) {
      addEquipment(formData);
    }
  };

  const isFormEmpty = Object.values(formData).every((value) => value === '');

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>
        {tabIndex === 0 ? 'CADASTRO EM MASSA' : 'CADASTRO UNITÁRIO'}
        <Typography variant="subtitle2">
          {tabIndex === 0
            ? 'Cadastre diversos equipamentos de uma só vez!'
            : 'Cadastrar um único equipamento'}
        </Typography>
      </DialogTitle>
      <Box className="tabs-container">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="standard"
          className="tabs-buttons"
          
          TabIndicatorProps={{ style: { display: 'none'} }} // Esconde o indicador padrão
        >
          <Tab label="CADASTRO EM MASSA" className={`tab-button ${tabIndex === 0 ? 'active' : ''}`} />
          <Tab label="CADASTRO UNITÁRIO" className={`tab-button ${tabIndex === 1 ? 'active' : ''}`} />
        </Tabs>
      </Box>
      <DialogContent>
        {tabIndex === 0 && (
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {!file ? (
              <div className={`upload-area ${isDragging ? 'dragging' : ''}`}>
                {!isDragging && (
                  <>
                    <AddCircleOutlineIcon className="upload-icon" />
                    <Typography className="upload-text">
                      arraste e solte o seu arquivo aqui, ou clique no botão para selecionar arquivo.
                    </Typography>
                    <input
                      accept=".xlsx"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      id="file-input"
                    />
                    <label htmlFor="file-input">
                      <Button variant="contained" component="span" sx={{backgroundColor:'#E0E0E0', color:'#00002e', borderRadius:'6px', transition:'background-color 0.3s ease, color 0.3s ease', minWidth:'auto'}}>
                        SELECIONAR ARQUIVO
                      </Button>
                    </label>
                  </>
                )}
                {isDragging && (
                  <Typography className="upload-text">Solte o arquivo para fazer o upload</Typography>
                )}
              </div>
            ) : (
              <Typography variant="body2" className="file-selected">
                Arquivo selecionado: {file.name}
              </Typography>
            )}
<Box
  className="download-template"
  onClick={() => {
    const link = document.createElement('a');
    link.href = "https://ceop.companyhub.app/content/modelo_importacao_ceop.xlsx";
    link.download = "modelo_importacao_ceop.xlsx";
    link.click();
  }}
  sx={{ cursor: 'pointer' }} // Estiliza o Box para indicar que é clicável
>
              <IconButton >
                <FilePresentOutlined sx={{color:'#00002e'}} />
              </IconButton>
              <Typography variant="body2" component="p" >
                Baixe o modelo disponível para fazer o cadastro em massa
              </Typography>
              <IconButton >
                <DownloadIcon sx={{color:'#00002e'}}/>
              </IconButton>
            </Box>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            {/* Campos para cadastro unitário */}

            <Autocomplete
              options={types}
              getOptionLabel={(option) => option || ''}
              loading={loading}
              value={formData.type}
              onChange={(event, newValue) => setFormData({ ...formData, type: newValue })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Autocomplete
              options={models}
              getOptionLabel={(option) => option || ''}
              loading={loading}
              value={formData.model}
              onChange={(event, newValue) => setFormData({ ...formData, model: newValue })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Modelo"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Autocomplete
              options={manufacturers}
              getOptionLabel={(option) => option || ''}
              loading={loading}
              value={formData.manufacturer}
              onChange={(event, newValue) => setFormData({ ...formData, manufacturer: newValue })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fabricante"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <TextField
              label="Código"
              name="code"
              fullWidth
              value={formData.code}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              label="Descrição"
              name="description"
              fullWidth
              value={formData.description}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              label="Número de Série"
              name="serialNumber"
              fullWidth
              value={formData.serialNumber}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              label="Número de Patrimônio"
              name="companyNumber"
              fullWidth
              value={formData.companyNumber}
              onChange={handleInputChange}
              margin="dense"
            />
            {/* Continuar com os outros campos */}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>
          CANCELAR
        </Button>
        <Button
  onClick={handleSave}
  color="success"
  variant="contained"
  sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus
  disabled={(tabIndex === 0 && !file) || (tabIndex === 1 && isFormEmpty) || adding || bulkAdding}
>
  {(adding || bulkAdding) ? (
    <>
      <CircularProgress size={24} style={{ marginRight: 8 }} sx={{color:'#E0E0E0'}} />
      AGUARDE...
    </>
  ) : 'CADASTRAR'}
</Button>
      </DialogActions>
      {(error || bulkError) && <Typography color="error" style={{ marginLeft: 16 }}>{error || bulkError}</Typography>}
    </Dialog>
  );
};

export default AddEquipmentModal;
