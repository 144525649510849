import React, { useContext, useState, useEffect, useMemo } from "react";
import { QRCodeCanvas } from 'qrcode.react';

import {
  Typography,
  Container,
  Grid,
  Button,
  Card,
  Box,
  IconButton,
  CssBaseline,
  Tabs,
  Tab,
  Divider,
  TextField,
  Popper,
  ClickAwayListener,
  Alert, 
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
  InputLabel,
  Select,
  Checkbox,
  MenuItem
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Assignment as AssignmentIcon,
  DeleteForever as DeletIcon,
  Edit as EditIcon,
  QrCodeOutlined as QrCodeOutlinedIcon,
  FileDownloadDoneOutlined as CheckMarckIcon,
  WarningOutlined as WarningIcon,
  DownloadOutlined as DownloadIcon,
  LocalPrintshopOutlined as Printicon,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { motion } from "framer-motion";
import useAllUsers from "../hooks/useAllUsers";
import AddItemModal from "../components/ceop/AddItemModal";
import AddUserModal from "../components/sections/users/AddUserModal";
import * as XLSX from 'xlsx';
import moment from 'moment';
import DefaultLayout from "../components/DefaultLayout";


const StatCard = ({ title, value, change }) => (
  <Card
    sx={{
      borderRadius: "10px",
      backgroundColor: "#FFFFFF",
      width: "200px",
      height: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: 1,
      boxShadow: "0 0 0 1px #CCCCCC",
    }}
  >
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Typography variant="subtitle2" color="#00002E">
        {title}
      </Typography>
      <ArrowDropDownIcon color="action" />
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      <Typography variant="h5" component="span" sx={{ color: "#00002E" }}>
        {value}
      </Typography>
      <Typography
        variant="body2"
        color={change.includes("-") ? "error.main" : "success.main"}
      >
        {change}
      </Typography>
    </Box>
  </Card>
);

const UsersDashboard = () => {
  useAuth();

  // Fetch user data using user Context
  const { user } = useContext(UserContext);
  const allUsersInfo = useAllUsers();


  const [selectedTab, setSelectedTab] = useState(0);
  const [isMinimizing, setIsMinimizing] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cardValues, setCardValues] = useState({
    total: { value: 0, change: "0%" },
    active: { value: 0, change: "0%" },
    inactive: { value: 0, change: "0%" },

  });

  const navigate = useNavigate();

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInactiveOpen, setModalInactiveOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [inactiveEquipment, setInactiveEquipment] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editEquipment, setEditEquipment] = useState(null);  // To store the equipment being edited
  const [selectedRows, setSelectedRows] = useState([]); // To store selected equipment
  const [detailsModalOpen, setDetailsModalOpen] = useState(false); // Modal state for showing details
  const [equipmentDetails, setEquipmentDetails] = useState(null);  // Store details of the selected equipment
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [qrCodeEquipment, setQrCodeEquipment] = useState(null); // Store equipment for QRCode generation
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [exportConfirmOpen, setExportConfirmOpen] = useState(false); // Novo estado para o diálogo de confirmação
// Define dinamicamente as opções de níveis de acesso com base no usuário logado
const accessLevels = useMemo(() => {
  if (user?.userInfo?.user_accesslevel.level === "superAdmin") {
    return [
      { label: "Super Admin", value: "superAdmin" },
      { label: "Administrador Local", value: "localAdmin" },
      { label: "Usuário Comum", value: "user" },
    ];
  } else if (user?.userInfo?.user_accesslevel.level === "localAdmin") {
    return [
      { label: "Administrador Local", value: "localAdmin" },
      { label: "Usuário Comum", value: "user" },
    ];
  } else {
    return [
      { label: "Usuário Comum", value: "user" },
    ];
  }
}, [user]);
    


  // Handle animation complete
  const handleAnimationComplete = () => {
    if (isMinimizing) {
      navigate("/modules"); // Navigate after the animation completes
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSearchClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchOpen((prev) => !prev);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClickAway = () => {
    setSearchOpen(false);
  };

  const columns = [
    {
      field: "user_isactive",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        let statusText = "";
        let blinkClass = "";
  
        // Definindo o texto do status
        switch (row.user_isactive) {
          case 0:
            statusText = "INATIVO";
            break;
          case 1:
            statusText = "ATIVO";
            break;
          default:
            statusText = "DESCONHECIDO"; // Fallback if the status is not recognized
            break;
        }
  
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{statusText}</span>
            {blinkClass && <span className={blinkClass}></span>}
          </div>
        );
      },
    },
    { field: "user_badge", headerName: "Matrícula", width: 100 },
    { field: "user_firstname", headerName: "Nome", flex: 1 },
    { field: "user_companyfunction", headerName: 'Função', flex: 1 },
    { field: "user_companystation", headerName: 'Seção', flex: 1 },
    {
      field: "user_modulesallowed",
      headerName: "Módulos",
      flex: 1,
    },    ...(user?.userInfo?.user_accesslevel.level !== "user"
      ? [
          {
            field: "actions",
            headerName: "Ações",
            width: 250,
            sortable: false,
            renderCell: (params) => (
              <Box
                display="flex"
                justifyContent="space-around"
                width="100%"
                padding={1}
                height="100%"
              >
                <IconButton
                  aria-label="edit"
                  disabled={params.row.status === 1}
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setEditEquipment(params.row);
                    setEditModalOpen(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  disabled={
                    params.row.status === 66 ||
                    params.row.status === 99 ||
                    params.row.status === 1
                  }
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                >
                  <DeletIcon />
                </IconButton>
                <IconButton
                  aria-label="qr-code"
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  onClick={() => handleQRCodeClick(params.row.user_badge)}
                >
                  <QrCodeOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="checkmark"
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  disabled={
                    params.row.status !== 66 &&
                    params.row.status !== 99 &&
                    params.row.status !== 1 &&
                    params.row.status !== 0
                  }
                  onClick={() => {
                    setSelectedEquipment(params.row);
                    setModalOpen(true);
                  }}
                >
                  <CheckMarckIcon />
                </IconButton>
                <IconButton
                  aria-label="warning"
                  sx={{
                    color: "#00002E",
                    backgroundColor: "#CECECE",
                    borderRadius: "10px",
                  }}
                  disabled={params.row.status === 66 || params.row.status === 1}
                  onClick={() => {
                    setInactiveEquipment(params.row);
                    setModalInactiveOpen(true);
                  }}
                >
                  <WarningIcon />
                </IconButton>
              </Box>
            ),
          },
        ]
      : []),
  ];
  
  

  const handleConfirm = () => {
    if (selectedEquipment) {
      setEquipmentAvailable(selectedEquipment); // Call the function to update status
    }
    setModalOpen(false); // Close the modal
  };
  
  const handleClose = () => {
    setModalOpen(false); // Close the modal without doing anything
  };


  const rows = useMemo(() => {
    if (!allUsersInfo || allUsersInfo.length === 0) {
      return [];
    }
  
    // A lógica do usuário atual
    const currentUser = user?.userInfo;
  
    return allUsersInfo
    .filter((userItem) => {
      // SuperAdmin pode ver todos os usuários
      if (currentUser?.user_accesslevel.level === "superAdmin") {
        return true;
      }
  
        // LocalAdmin só pode ver usuários que possuem módulos permitidos que o admin local também possui
        if (currentUser?.user_accesslevel.level === "localAdmin") {
          const currentUserModules = currentUser.user_modulesallowed?.modules || [];
          const userModules = userItem.user_modulesallowed?.modules || [];
  
          // Verifica se há interseção entre os módulos do usuário logado e o usuário em questão
          const hasSharedModule = userModules.some((module) =>
            currentUserModules.includes(module)
          );
  
          // Retorna verdadeiro se houver interseção de módulos ou se for o próprio usuário
          return hasSharedModule || userItem.user_badge === currentUser.user_badge;
        }
  
        // Caso contrário, retorna falso (sem permissões)
        return false;
      })
      .map((user) => ({
        id: user.id,
        user_badge: user.user_badge,
        user_firstname: user.user_firstname + " " + user.user_surname,
        user_surname: user.user_surname,
        user_cpf: user.user_cpf,
        user_rg: user.user_rg,
        user_areasection: user.user_areasection,
        user_companyfunction: user.user_companyfunction,
        user_companystation: user.user_companystation,
        user_companyadmissiondate: user.user_companyadmissiondate,
        user_birthday: user.user_birthday,
        user_accesslevel: user.user_accesslevel,
        user_isactive: user.user_isactive,
        user_modulesallowed: Array.isArray(user.user_modulesallowed.modules)
          ? user.user_modulesallowed.modules.join(", ")
          : user.user_modulesallowed.modules || "Nenhum módulo permitido",
      }));
  }, [allUsersInfo, user]);
  

  // Effect to filter rows based on search term and selected tab
  useEffect(() => {
    if (!rows) {
      return;
    }
  
    let filtered = [...rows];

  // Filtra os usuários com base no termo de busca
  if (searchTerm) {
    filtered = filtered.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }

  // Filtro baseado na aba selecionada
  switch (selectedTab) {
    case 1:
      // Administradores
      filtered = filtered.filter((row) => row.user_isactive === 1);
      break;
    case 2:
      // Operadores
      filtered = filtered.filter((row) => row.user_isactive === 0);
      break;
    default:
      // Todos os usuários
      break;
  }

  setFilteredRows(filtered);
}, [searchTerm, rows, selectedTab]);

useEffect(() => {
  if (!alertOpen) {
    setAlertOpen(false);
  }
}, [alertOpen]);

// Effect to calculate card values once when equipments change
useEffect(() => {
  let overdue12hCount = 0;
  let overdue24hCount = 0;

  console.log(allUsersInfo);
  const currentTime = moment(); // Use moment to get the current time

  allUsersInfo.forEach((user) => {
    // Only consider equipment that is currently in use
    if (user.currentStatus === 1) {
      // Sort the history for each equipment
      const sortedHistory = (allUsersInfo.badge || []).sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      // Get the most recent history entry
      const lastHistoryEntry = sortedHistory[0];

      if (lastHistoryEntry && lastHistoryEntry.date) {
        const lastRegistryMoment = moment(lastHistoryEntry.date);
        if (lastRegistryMoment.isValid()) {
          const diffInHours = currentTime.diff(lastRegistryMoment, 'hours');

          if (diffInHours > 24) {
            overdue24hCount++;
          } else if (diffInHours > 12) {
            overdue12hCount++;
          }
        }
      }
    }
  });

  // Calculate counts
  const total = allUsersInfo.length;
  const activeUsers = allUsersInfo.filter(user => user.user_isactive === 1);
  const active = activeUsers.length; 
  const inactiveUsers = allUsersInfo.filter(user => user.user_isactive === 0);
  const inactive = inactiveUsers.length; 

  setCardValues({
    total: { value: total, change: '' },
    active: { value: active, change: '' },
    inactive: { value: inactive, change: ' ' },
    
  });
}, [allUsersInfo, setCardValues]);


  const setEquipmentAvailable = async (user) => {
    try {
      const response = await fetch("https://serverwinglet.winglet.app/api/update_equipment", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          id: user.user_badge,
          equipment: user.code,
          status: 0,  // Mark as available
          user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`,
          funcao: user?.userInfo?.user_companyfunction,
          badge: 'N/A',
          employeeName: "N/A",
          observations: 'Equipamento retornado de dano',
          photos: [], // URLs or empty if not applicable
        }),
        headers: {
          Authorization: `${sessionStorage.getItem('accessToken')}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        setAlertOpen(true);
        setAlertMessage(`Operação realizada com sucesso para o equipamento ${user.code}`);
        setAlertSeverity('success');
      } else {
        setAlertOpen(true);
        setAlertMessage("Ops! Algo deu errado. Tente novamente");
        setAlertSeverity('warning');
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertMessage(`Ops! Não foi possível completar a operação. Erro: ${error.message}`);
      setAlertSeverity('error');
    }
  };

  const ConfirmDialog = ({ open, handleClose, handleConfirm, equipment }) => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirmar Atualização de Status"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem certeza de que deseja <strong>disponibilizar o equipamento{" "}
          {equipment?.code}</strong> para uso, novamente?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'10px'}}>
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const EditEquipmentDialog = ({ open, handleClose, equipment, handleSave }) => {

    const allModules = [
      "CEOP",
      "SPAX",
      "GUARDA-VOLUMES",
      "GSE",
      "PNAE",
      "VOOS",
      "SKYGUARDIAN",
    ];

    const [formData, setFormData] = useState({
      badge: equipment?.user_badge || '',
      name: equipment?.user_firstname || '',
      function: equipment?.user_companyfunction || '',
      section: equipment?.user_companystation || '',
      modules: equipment?.user_modulesallowed || [],
      accesslevel: equipment?.user_accesslevel.level || '',
      //serialnumber: equipment?.serialnumber || '',
      //companynumber: equipment?.companynumber || '',
      user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`
      // Add more fields as needed
    });
  
    useEffect(() => {
      if (equipment) {
        setFormData({
          badge: equipment?.user_badge,
          name: equipment?.user_firstname,
          function: equipment?.user_companyfunction,
          section: equipment?.user_companystation,
          modules: equipment?.user_modulesallowed || [],
          accesslevel: equipment?.user_accesslevel.level,
          //serialNumber: equipment.serialnumber,
          //companyNumber: equipment.companynumber,
          user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`


          // Pre-fill other fields
        });
      }
    }, [equipment]);

// Função para alternar o estado dos switches
const handleModuleToggle = (module) => {
  setFormData((prevData) => {
    // Verifica se prevData.modules é uma matriz, caso contrário, inicializa como uma matriz vazia
    const modulesArray = Array.isArray(prevData.modules) ? prevData.modules : [];

    const isModuleAllowed = modulesArray.includes(module);
    return {
      ...prevData,
      modules: isModuleAllowed
        ? modulesArray.filter((mod) => mod !== module)
        : [...modulesArray, module],
    };
  });
};

  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = () => {
      // Call the save handler passed as a prop
      handleSave(formData);
      handleClose();  // Close the modal after saving
    };
  
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Usuário</DialogTitle>
        <DialogContent>
          <TextField
            label="Matrícula"
            name="badge"
            disabled={true}
            value={formData.badge}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Nome"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Função"
            name="function"
            value={formData.function}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Seção"
            name="section"
            value={formData.section}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
              <InputLabel id="access-level-select-label" >Nível de Acesso</InputLabel>
              <Select
                fullWidth
                labelId="access-level-select-label"
                id="access-level-select"
                value={formData.accesslevel}
                label="Nível de Acesso"
                onChange={(event) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    accesslevel: event.target.value,
                  }));
                }}
              >
                {/* Mapeia as opções de accessLevels dinamicamente */}
                {accessLevels.map((level) => (
                  <MenuItem key={level.value} value={level.value}>
                    {level.label}
                  </MenuItem>
                ))}
              </Select>

<Box sx={{ display: "flex", flexDirection: "column", mt: 2}}>
<Typography variant="h6" sx={{ display: user?.userInfo?.user_accesslevel.level === "superAdmin" ? "flex" : "none" }}>
  Módulos Permitidos
</Typography>
  <Grid container spacing={2}>
    {user?.userInfo?.user_accesslevel.level === "superAdmin" &&
    allModules
      .filter((module) => ![].includes(module))
      .map((module) => (
        <Grid item xs={6} key={module}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.modules.includes(module)}
                onChange={() => handleModuleToggle(module)}
                sx={{
                  "& .Mui-checked": {
                    color: "#007C39",
                  },
                  "& .Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "#A5D6A7",
                  },
                }}
              />
            }
            label={module}
          />
        </Grid>
      ))}
  </Grid>

  {/* Checkbox para "Faz entrega / devolução de equipamentos" */}
  {(formData.modules.includes("CEOP") || formData.modules.includes("SPAX")) && (
    <Box sx={{ mt: 2 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.modules.includes("PERFORM_DELIVERY")}
            onChange={() => handleModuleToggle("PERFORM_DELIVERY")}
            sx={{
              color: "error",
              "&.Mui-checked": {
                color: "#007C39",
              },
            }}
          />
        }
        label="Faz entrega / devolução de equipamentos"
      />
    </Box>
  )}
</Box>

<FormControlLabel
sx={{ mt: 2 }}
        control={
          <Checkbox
            
            onChange={''}
            sx={{
              color: "error",
              "&.Mui-checked": {
                color: "#007C39",
              },
            }}
          />
        }
        label="Solicitar troca de senha no próximo login"
      />
          {/* Add more fields as necessary */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'10px'}}>Cancelar</Button>
          <Button onClick={handleSubmit} color="primary">Salvar</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSaveEdit = async (updatedEquipment) => {
    try {
      const response = await fetch(`https://serverwinglet.winglet.app/api/update_equipment/${updatedEquipment.id}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(updatedEquipment),
        headers: {
          Authorization: `${sessionStorage.getItem('accessToken')}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        // Refresh the equipment data (or update it in the UI)
        setAlertOpen(true);
        setAlertMessage(`Equipamento ${updatedEquipment.code} atualizado com sucesso`);
        setAlertSeverity('success');
      } else {
        setAlertOpen(true);
        setAlertMessage("Ops! Algo deu errado ao atualizar o equipamento.");
        setAlertSeverity('warning');
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertMessage(`Erro ao atualizar equipamento: ${error.message}`);
      setAlertSeverity('error');
    }
  };
  
  const handleRowSelection = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const EquipmentDetailsDialog = ({ open, handleClose, equipment }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageModalOpen, setImageModalOpen] = useState(false);
  
    if (!equipment) {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Detalhes do Equipamento</DialogTitle>
          <DialogContent>
            <Typography>Nenhum equipamento selecionado.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      );
    }
  
    // Sort the history by date (descending)
    const sortedHistory = [...(equipment.history || [])].sort((a, b) => new Date(b.date) - new Date(a.date));
  
    const handleImageClick = (photo) => {
      setSelectedImage(photo);
      setImageModalOpen(true);
    };
  
    const handleImageModalClose = () => {
      setImageModalOpen(false);
      setSelectedImage(null);
    };
  
    const exportToExcel = () => {
      const data = sortedHistory.map(entry => ({
        Data: new Date(entry.date).toLocaleString(),
        'Usuário Responsável': entry.user,
        'Ação Realizada': entry.action,
        'Observações': entry.observations,
        'Fotos': entry.photos ? entry.photos.join(', ') : '',
        'Devolvido por:/ Entregue para:': entry.to_from,
        'Nome': entry.to_from_fullName,
        'Função': entry.to_from_function,
        'Status Anterior': entry.status_before
      }));
    
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "History");
    
      XLSX.writeFile(workbook, `${equipment.code}_history.xlsx`);
    };
  
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Detalhes do Equipamento</DialogTitle>
          <DialogContent>
            <Typography variant="h6">ID: {equipment.code}</Typography>
            <Typography variant="body1">Equipamento: {equipment.type}</Typography>
            <Typography variant="body1">Modelo: {equipment.model}</Typography>
            <Typography variant="body1">Fabricante: {equipment.manufacturer}</Typography>
            <Typography variant="body1">Número de Série: {equipment.serialNumber}</Typography>
            <Typography variant="body1">Patrimônio: {equipment.companyNumber}</Typography>
            <Typography variant="body1">Departamento: {equipment.department}</Typography>
  
            <Divider sx={{ marginY: 2 }} />
  
            <Typography variant="h6">Histórico</Typography>
            <Divider sx={{ marginY: 1 }} />
            <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
              {sortedHistory.length === 0 ? (
                <Typography>Nenhum histórico disponível.</Typography>
              ) : (
                sortedHistory.map((entry, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <Typography variant="body2" >
                      Data do registro: {new Date(entry.date).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" >
                      Status do Equipamento: {entry.action}
                    </Typography>
                    <Typography variant="body2" >
                      Último uso por: {entry.to_from_fullName || "Desconhecido"} ({entry.to_from_function || "Função não informada"})
                    </Typography>
                    {entry.observations && (
                      <Typography variant="body2" >
                        Observações: {entry.observations}
                      </Typography>
                    )}
                    <Typography variant="body2" >
                      Fotos:
                    </Typography>
                    {entry.photos && entry.photos.length > 0 && (
                      <Box sx={{ marginTop: 1 }}>
                        {entry.photos.map((photo, photoIndex) => (
                          <img
                            key={photoIndex}
                            src={photo}
                            alt={`Histórico do Equipamento ${photoIndex}`}
                            style={{ maxWidth: '100%', maxHeight: 100, marginBottom: 10, cursor: 'pointer' }}
                            onClick={() => handleImageClick(photo)}
                          />
                        ))}
                      </Box>
                    )}
                    
                    <Typography variant="body2" >
                      Responsável: {entry.user}
                    </Typography>
                    <Divider sx={{ marginTop: 1 }} />
                  </Box>
                ))
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={exportToExcel}>Exportar para Excel</Button>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
  
        <Dialog open={imageModalOpen} onClose={handleImageModalClose}>
          <DialogContent>
            {selectedImage && (
              <img src={selectedImage} alt="Imagem ampliada" style={{ width: '100%' }} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleImageModalClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleAssignmentClick = () => {
    if (selectedRows.length < 1) {
      // Abrir o diálogo de confirmação para exportação
      setExportConfirmOpen(true);
    } else {
      const selectedEquipment = allUsersInfo.find(
        (equipment) => equipment.id === selectedRows[0]
      );
      setEquipmentDetails(selectedEquipment);
      setDetailsModalOpen(true);
    }
  };
  
  // Função para exportar os dados após a confirmação
  const handleExportConfirm = () => {
    const data = filteredRows.map((equipment) => ({
      Código: equipment.code,
      Equipamento: equipment.equipment,
      Status: equipment.status,
      'Último Registro': equipment.lastRegistry,
      'Usado Por': equipment.usedBy,
      Setor: equipment.department,
      'Número de Série': equipment.serialnumber,
      Patrimônio: equipment.companynumber,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventário');
    XLSX.writeFile(workbook, 'inventario_equipamentos.xlsx');
  
    setAlertOpen(true);
    setAlertMessage("Lista de equipamentos exportada com sucesso!");
    setAlertSeverity('success');
    setExportConfirmOpen(false); // Fechar o diálogo de confirmação
  };
  
  // Diálogo de confirmação para exportação
  const ExportConfirmDialog = ({ open, onClose, onConfirm }) => (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="export-confirm-dialog-title"
      aria-describedby="export-confirm-dialog-description"
    >
      <DialogTitle id="export-confirm-dialog-title">
        Exportar Invetário
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="export-confirm-dialog-title" sx={{marginBottom:'10px'}}>
          Deseja realmente exportar o Inventário?
        </DialogContentText>
        <DialogContentText id="export-confirm-dialog-description" sx={{fontSize:'12px'}}>
          <strong>Nota:</strong> Se deseja visualizar o histórico de um equipamento específico, selecione-o na lista antes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>
          Cancelar
        </Button>
        <Button onClick={onConfirm} sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
  

  const handleQRCodeClick = (equipment) => {
    setQrCodeEquipment(equipment);
    setQrCodeModalOpen(true);
  };

  const QRCodeDialog = ({ open, handleClose, equipment }) => {
    const qrRef = React.createRef(); // To reference the QRCode for downloading
  
    const handleDownload = () => {
      const qrCanvas = qrRef.current.querySelector('canvas');
      const pngUrl = qrCanvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
  
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${equipment.code}-qrcode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
  
    const handlePrint = () => {
      const qrCanvas = qrRef.current.querySelector('canvas');
      const qrWindow = window.open('');
      qrWindow.document.write('<html><head><title>Print QRCode</title></head><body>');
      qrWindow.document.write(`<img src="${qrCanvas.toDataURL()}" />`);
      qrWindow.document.write('</body></html>');
      qrWindow.document.close();
      setTimeout(() => {
        qrWindow.focus();
        qrWindow.print();
      }, 500); // Delay to ensure image is rendered before print
    };
  
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{ m: 0, p: 2, textAlign:'center'}}>
          <strong>QR Code</strong>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 5,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {equipment ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 2
              }}
            >
              <Box ref={qrRef}>
                <QRCodeCanvas value={equipment} size={300} fgColor="#2e3436" level="M"/>
              </Box>
              <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1}}>
                {equipment}
              </Typography>
            </Box>
          ) : (
            <Typography>Nenhum equipamento selecionado</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Printicon />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

const handleConfirmInactive = async () => {
  if (inactiveEquipment) {
    await setEquipmentInactive(inactiveEquipment); // Call the function to update status
  }
  setModalInactiveOpen(false); // Close the modal
};

const handleCloseInactive = () => {
  setModalInactiveOpen(false); // Close the modal without doing anything
};

const setEquipmentInactive = async (equipment) => {
  try {
    const response = await fetch("https://serverwinglet.winglet.app/api/update_equipment", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        id: equipment.id,
        equipment: equipment.code,
        status: 99,  // Mark as inactive
        user: `${user?.userInfo?.user_badge} - ${user?.userInfo?.user_firstname} ${user?.userInfo?.user_surname}`,
        funcao: user?.userInfo?.user_companyfunction,
        badge: 'N/A',
        employeeName: "N/A",
        observations: 'Equipamento inativado',
        photos: [], // URLs or empty if not applicable
      }),
      headers: {
        Authorization: `${sessionStorage.getItem('accessToken')}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setAlertOpen(true);
      setAlertMessage(`Operação realizada com sucesso para o equipamento ${equipment.code}`);
      setAlertSeverity('success');
    } else {
      setAlertOpen(true);
      setAlertMessage("Ops! Algo deu errado. Tente novamente");
      setAlertSeverity('warning');
    }
  } catch (error) {
    setAlertOpen(true);
    setAlertMessage(`Ops! Não foi possível completar a operação. Erro: ${error.message}`);
    setAlertSeverity('error');
  }
};

// Ensure the ConfirmDialogInactive component is correctly used
const ConfirmDialogInactive = ({ open, handleCloseInactive, handleConfirmInactive, equipment }) => (
  <Dialog
    open={open}
    onClose={handleCloseInactive}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Confirmar Atualização de Status"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Tem certeza de que deseja Inativar o equipamento{" "}
        <strong>{equipment?.code}</strong>?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseInactive} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>
        Cancelar
      </Button>
      <Button
        onClick={handleConfirmInactive}
        sx={{backgroundColor:'rgba(40, 141, 9, 1)', color:'#FFF', cursor:'pointer', borderRadius:'6px'}} autoFocus
      >
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

// Handle menu open
const handleMenuClick = (event) => {
  setAnchorEl(event.currentTarget);
};

// Handle menu close
const handleMenuClose = () => {
  setAnchorEl(null);
};

// Handle click to add an equipment
const handleAddEquipmentClick = () => {
  handleMenuClose();
  setAddUserModalOpen(true);
};


// Function to handle successful operations
const handleAddSuccess = (message) => {
  setAlertMessage(message);
  setAlertOpen(true);
  setAlertSeverity('success');
};

const handleLeituraDigitalClick = () => {
  navigate('/modules/ceop/scan');
};

  
  return (
    <motion.div
      initial={{ opacity: 1, scale: 1 }}
      animate={
        isMinimizing
          ? { opacity: 0, scale: 0.5, x: 200, y: 200 }
          : { opacity: 1, scale: 1 }
      }
      transition={{ duration: 0.2 }}
      onAnimationComplete={handleAnimationComplete}
    >
      <ConfirmDialog
      open={modalOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      equipment={selectedEquipment} // Pass the selected equipment to the modal
    />
    <EditEquipmentDialog
    open={editModalOpen}
    handleClose={() => setEditModalOpen(false)}
    equipment={editEquipment}  // Pass the selected equipment for editing
    handleSave={handleSaveEdit}  // Pass the save handler
  />
  <EquipmentDetailsDialog
    open={detailsModalOpen}
    handleClose={() => setDetailsModalOpen(false)}
    equipment={equipmentDetails}
  />
    <QRCodeDialog
    open={qrCodeModalOpen}
    handleClose={() => setQrCodeModalOpen(false)}
    equipment={qrCodeEquipment} // Equipamento selecionado
  />
    <ConfirmDialogInactive
      open={modalInactiveOpen}
      handleCloseInactive={handleCloseInactive}
      handleConfirmInactive={handleConfirmInactive}
      equipment={inactiveEquipment}
    />
    <ExportConfirmDialog
      open={exportConfirmOpen}
      onClose={() => setExportConfirmOpen(false)}
      onConfirm={handleExportConfirm}
    />
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <CssBaseline />
        <DefaultLayout title='TESTE'>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#E0E0E0",
            padding: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            maxWidth="90hv"
            sx={{ mt: 0, backgroundColor: "transparent", flexGrow: 0 }}
          >
            <Typography variant="h5" gutterBottom sx={{ color: "#00002E" }}>
              Resumo
            </Typography>
            <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          columns={1}
          justifyContent="flex-start"
          sx={{ color: "#00002E" }}
        >
          {[
            { title: "Total", value: cardValues.total.value, change: cardValues.total.change },
            { title: "Ativos", value: cardValues.active.value, change: '' },
            { title: "Inativos", value: cardValues.inactive.value, change: '' },

            
          ].map((card, index) => (
            <React.Fragment key={index}>
              <Grid item>
                <StatCard
                  title={card.title}
                  value={card.value}
                  change={card.change}
                />
              </Grid>
              {index < 2 && (
                <Grid item>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      bgcolor: "#CCCCCC",
                      height: "60px",
                      alignSelf: "center",
                      mx: 0.5,
                      my: 1,
                      color: "#00002E",
                    }}
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
            <Box
              sx={{
                mt: 5,
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                paddingY: 1,
                paddingX: 2,
                width: "65%",
                color: "#00002E",
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                textColor="primary"
                TabIndicatorProps={{
                  sx: {
                    display: "none",
                    color: "#00002E",
                  },
                }}
              >
                <Tab
                  label="Todos os usuários"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
                <Tab
                  label="Usuários Ativos"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002e",
                      borderRadius: "10px",
                    },
                  }}
                />
                <Tab
                  label="Usuários Inativos"
                  sx={{
                    color: "#00002E",
                    "&.Mui-selected": {
                      backgroundColor: "#B0BEC5",
                      color: "#00002E",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Tabs>
              <Box sx={{ ml: 35, display: "flex", gap: 2, justifyContent:'start' }}>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<AddCircleIcon />}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#A5D6A7",
                    border: "1px solid #007C39",
                    color: "#007C39",
                    borderRadius: "10px",
                  }}
                  onClick={handleAddEquipmentClick}
                  disabled={user?.userInfo?.user_accesslevel.level === "user"} 
                >
                  Adicionar Usuários
                </Button>
      <AddItemModal
        open={addItemModalOpen}
        onClose={() => setAddItemModalOpen(false)}
        onSuccess={handleAddSuccess}
      />
                    <AddUserModal
                open={addUserModalOpen}
                onClose={() => setAddUserModalOpen(false)}
                onSuccess={handleAddSuccess}
              />

                <Box
              sx={{
                position: "fixed",
                right: "30px",
                display: "flex",
                gap: 0.5,
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
                zIndex: "1600",
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  <IconButton
                    size="large"
                    sx={{
                      color: "#00002E",
                      backgroundColor: "rgba(238, 238, 238, 0.35)",
                      borderRadius: "20%",
                      mb: { xs: 1, md: 0 },
                    }}
                    onClick={handleSearchClick}
                  >
                    <SearchIcon fontSize="medium" />
                  </IconButton>
                  <Snackbar
  open={alertOpen}
  autoHideDuration={3000}
  onClose={(event, reason) => {
    if (reason !== 'clickaway') {
      setAlertOpen(false);
    }
  }}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  sx={{
    width: 500,
    bottom: 0,
    marginRight: 20,
    maxWidth: 500,
    zIndex: 999,
  }}
>
  <Alert
    variant="filled"
    severity={alertSeverity}
    onClose={() => setAlertOpen(false)} // Fechar o alerta ao clicar no "x"
    sx={{
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'auto',
      textOverflow: 'ellipsis', // Use ellipsis para textos longos
    }}
  >
    {alertMessage}
  </Alert>
</Snackbar>
                  <Popper
                    open={searchOpen}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    disablePortal
                    sx={{ zIndex: 1600 }}
                  >
                    {searchOpen && (
                      <Box
                        sx={{
                          mt: 1,
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                          boxShadow: 3,
                          width: "600px",
                          padding: "8px",
                        }}
                      >
                        <TextField
                          size="small"
                          variant="outlined"
                          placeholder="Buscar..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </Box>
                    )}
                  </Popper>
                </Box>
              </ClickAwayListener>
              <IconButton
                size="large"
                sx={{
                  color: "#00002E",
                  backgroundColor: "rgba(238, 238, 238, 0.35)",
                  borderRadius: "20%",
                  mb: { xs: 1, md: 0 },
                }}
              >
                <SettingsIcon fontSize="medium" />
              </IconButton>
              <IconButton
                size="large"
                onClick={handleAssignmentClick}
                sx={{
                  color: "#00002E",
                  backgroundColor: "rgba(238, 238, 238, 0.35)",
                  borderRadius: "20%",
                }}
              >
                <AssignmentIcon fontSize="medium" />
              </IconButton>
            </Box>
              </Box>
            </Box>
          </Container>
          <Box sx={{ mt: 2, flexGrow: 1, padding: 3 }}>
          
          <DataGrid
              rows={filteredRows}
              columns={columns}
              pageSize={5}
              checkboxSelection
              disableExtendRowFullWidth
              disableColumnResize={false}
              autosizeOnMount={true}
              disableRowSelectionOnClick
              onRowSelectionModelChange={handleRowSelection}
              sx={{
                zIndex: 999,
                backgroundColor: "transparent",
                borderRadius: "10px",
                height: "100%",
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: "#FFFFFF",
                },
                ".MuiDataGrid-row": {
                  borderBottom: "1px solid #B0BEC5",
                },
              }}
            />
          </Box>
        </Box>
      </DefaultLayout>
      </Box>
      
    </motion.div>
  );
};

export default UsersDashboard;
