import { useState, useEffect } from 'react';

const useEmployeeInfo = (badge) => {
  const [employee, setEmployee] = useState(null);
  const token = sessionStorage.getItem('accessToken');

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(`https://serverwinglet.winglet.app/api/employees/${badge}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`, // Include the token in the Authorization header
          },
        });

        if (response.ok) {
          const data = await response.json();
          setEmployee(data.employee); // Access the equipment property
        } else {
          console.error('Failed to fetch equipment data:');
          setEmployee(null);
        }
      } catch (error) {
        console.error('Error fetching equipment data:', error);
      }
    };

    if (token && badge) {
      fetchEmployee();
    }
  }, [token, badge]);

  return employee;
};

export default useEmployeeInfo;