import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
  Stack,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { FilePresentOutlined, LiveTv } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br' // load on demand

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useAddUser from '../../../hooks/sections/users/useAddUsers';
import useBulkAddUser from '../../../hooks/sections/users/useBulkAddUsers';
import useEmployeeInfo from '../../../hooks/sections/users/getEmployees';
import '../../../App.css'; // Importando o CSS principal

const AddUserModal = ({ open, onClose, onSuccess }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [formData, setFormData] = useState({
    badge: '',
    name: '',
    userfunction: '',
    section: '',
    cpf: '',
    rg: '',
    birthday: dayjs(),
    admission: dayjs(),
    modulesAllowed: [],
    accessLevel: '',
    delivery: false,
  });

  const { addUser, loading: adding, error, success } = useAddUser();
  const { bulkAddUser, loading: bulkAdding, error: bulkError, success: bulkSuccess } = useBulkAddUser();
  
  const now = dayjs(new Date().toISOString());
  const minimalDate = now.subtract(18, 'year');
  const employee = useEmployeeInfo(formData.badge); // Usa o hook para buscar os dados do colaborador

  useEffect(() => {
    // Quando os dados do colaborador são retornados, atualize o formulário
    if (employee) {
      setFormData((prev) => ({
        ...prev,
        name: employee.name,
        userfunction: employee.company_role,
        section: employee.section,
        cpf: employee.cpf,
        rg: employee.rg,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        name: '',
        userfunction: '',
        section: '',
        cpf: '',
        rg: '',
      }));
    }
  }, [employee]);

  useEffect(() => {
    if (tabIndex === 1) {
      setFile(null);
    }
  }, [tabIndex]);

  const handleCancel = useCallback(() => {
    setFile(null);
    setIsDragging(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (success || bulkSuccess) {
      onSuccess('Equipamento(s) adicionado(s) com sucesso');
      handleCancel();
    }
  }, [success, bulkSuccess, onSuccess, handleCancel]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFile(selectedFile);
    } else {
      alert('Por favor, selecione um arquivo .xlsx válido.');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setFile(droppedFile);
      } else {
        alert('Por favor, selecione um arquivo .xlsx válido.');
      }
      e.dataTransfer.clearData();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (tabIndex === 0) {
      if (file) {
        bulkAddUser(file);
      } else {
        alert('Por favor, selecione um arquivo para continuar.');
      }
    } else if (tabIndex === 1) {
      addUser(formData);
    }
  };

  const isFormEmpty = Object.values(formData).every((value) => value === '');

  const accessLevels = [
    { label: 'Super Admin', value: 'superAdmin' },
    { label: 'Administrador Local', value: 'localAdmin' },
    { label: 'Usuário Comum', value: 'user' },
  ];
  
  const modules = [
    { label: 'CEOP', value: 'CEOP' },
    { label: 'SPAX', value: 'SPAX' },
    { label: 'GUARDA-VOLUMES', value: 'LOCKERS' },
  ];

  const handleDateChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDeliveryChange = (e) => {
    setFormData((prev) => ({ ...prev, delivery: e.target.checked }));
  };

    // Função para lidar com alterações do nível de acesso no Autocomplete
    const handleAccessLevelChange = (event, newValue) => {
      setFormData((prev) => ({ ...prev, accessLevel: newValue }));
    };
  
    // Função para lidar com alterações dos módulos no Autocomplete
    const handleModulesChange = (event, newValue) => {
      setFormData((prev) => ({ ...prev, modulesAllowed: newValue }));
    };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>
        {tabIndex === 0 ? 'CADASTRO EM MASSA' : 'CADASTRO UNITÁRIO'}
        <Typography variant="subtitle2">
          {tabIndex === 0
            ? 'Cadastre diversos usuários de uma só vez!'
            : 'Cadastrar um único usuário'}
        </Typography>
      </DialogTitle>
      <Box className="tabs-container">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="standard"
          className="tabs-buttons"
          
          TabIndicatorProps={{ style: { display: 'none'} }} // Esconde o indicador padrão
        >
          <Tab label="CADASTRO EM MASSA" className={`tab-button ${tabIndex === 0 ? 'active' : ''}`} />
          <Tab label="CADASTRO UNITÁRIO" className={`tab-button ${tabIndex === 1 ? 'active' : ''}`} />
        </Tabs>
      </Box>
      <DialogContent>
        {tabIndex === 0 && (
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {!file ? (
              <div className={`upload-area ${isDragging ? 'dragging' : ''}`}>
                {!isDragging && (
                  <>
                    <AddCircleOutlineIcon className="upload-icon" />
                    <Typography className="upload-text">
                      arraste e solte o seu arquivo aqui, ou clique no botão para selecionar arquivo.
                    </Typography>
                    <input
                      accept=".xlsx"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      id="file-input"
                    />
                    <label htmlFor="file-input">
                      <Button variant="contained" component="span" sx={{backgroundColor:'#E0E0E0', color:'#00002e', borderRadius:'6px', transition:'background-color 0.3s ease, color 0.3s ease', minWidth:'auto'}}>
                        SELECIONAR ARQUIVO
                      </Button>
                    </label>
                  </>
                )}
                {isDragging && (
                  <Typography className="upload-text">Solte o arquivo para fazer o upload</Typography>
                )}
              </div>
            ) : (
              <Typography variant="body2" className="file-selected">
                Arquivo selecionado: {file.name}
              </Typography>
            )}
<Box
  className="download-template"
  onClick={() => {
    const link = document.createElement('a');
    link.href = "https://ceop.companyhub.app/content/modelo_importacao_ceop.xlsx";
    link.download = "modelo_importacao_ceop.xlsx";
    link.click();
  }}
  sx={{ cursor: 'pointer' }} // Estiliza o Box para indicar que é clicável
>              <IconButton href="/path/to/model.xlsx" download>
                <FilePresentOutlined sx={{color:'#00002e'}} />
              </IconButton>
              <Typography variant="body2" component="span">
                Baixe o modelo disponível para fazer o cadastro em massa
              </Typography>
              <IconButton href="/path/to/model.xlsx" download>
                <DownloadIcon sx={{color:'#00002e'}}/>
              </IconButton>
            </Box>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <TextField
            label="Matrícula"
            name="badge"
            value={formData.badge}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            required
          />
          <TextField
            label="Nome"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            required
            disabled={!!employee}
          />
          <TextField
            label="Função"
            name="function"
            value={formData.userfunction}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            required
            disabled={!!employee}
          />
          <TextField
            label="Seção"
            name="section"
            value={formData.section}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            required
            disabled={!!employee}
          />
                    <TextField
            label="CPF"
            name="cpf"
            value={formData.cpf}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            required
            disabled={!!employee}
          />
                              <TextField
            label="RG"
            name="rg"
            value={formData.rg}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            required
            disabled={!!employee}
          />
          <Box fullWidth sx={{mt:2, display:'grid', gridRow:'auto', rowGap:2}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Data de Nascimento"
                format="DD/MM/YYYY"
                disableFuture
                minDate={minimalDate}
                value={formData.birthday}
                onChange={(value) => handleDateChange('birthday', value)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="dense" required />
                )}
              />
              <MobileDatePicker
                label="Data de Admissão"
                format="DD/MM/YYYY"
                disableFuture
                minDate={minimalDate}
                value={formData.admission}
                onChange={(value) => handleDateChange('admission', value)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="dense" required />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Stack spacing={2} sx={{ mt: 2 }}>
          <Autocomplete
            options={accessLevels}
            getOptionLabel={(option) => option?.label || ''}            
            value={formData.accessLevel}
            onChange={handleAccessLevelChange}
            renderInput={(params) => <TextField {...params} label="Nível de Acesso" required />}
          />
        </Stack>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Autocomplete
            multiple
            options={modules}
            getOptionLabel={(option) => option.label}
            value={formData.modulesAllowed}
            onChange={handleModulesChange}
            renderInput={(params) => <TextField {...params} label="Módulos Autorizados" required />}
          />
        </Stack>
    <FormControlLabel
          required
          control={
            <Checkbox
              checked={formData.delivery} // control the checked state
              onChange={handleDeliveryChange} // handle change
              sx={{ backgroundColor: 'success' }}
            />
          }
          label="Realiza entrega/devolução de equipamentos?"
          sx={{ mt: 2 }}
        />

    <Typography variant="caption" component="h2" color='error' sx={{mt:2}}>
          Atenção: a senha padrão é <strong>Winglet@Orbital2024</strong> 
</Typography>

<Typography variant="caption" component="h2">
          No primeiro acesso, o usuário será obrigado a alterar a senha
</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} sx={{backgroundColor:'#E0E0E0', color:'#00002E', cursor:'pointer', borderRadius:'6px'}}>
          CANCELAR
        </Button>
        <Button
  onClick={handleSave}
  color="success"
  variant="contained"
  disabled={(tabIndex === 0 && !file) || (tabIndex === 1 && isFormEmpty) || adding || bulkAdding}
>
  {(adding || bulkAdding) ? (
    <>
      <CircularProgress size={24} style={{ marginRight: 8 }} sx={{color:'#E0E0E0'}} />
      AGUARDE...
    </>
  ) : 'CADASTRAR'}
</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
