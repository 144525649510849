// components/EquipmentStatsModal.js
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { fetchEquipments } from '../../hooks/ceop/ceopEquipments'; // Ajuste o caminho conforme necessário

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement);

const EquipmentStatsModal = ({ open, onClose}) => {
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    if (open) {
      const fetchInitialEquipments = async () => {
        const fetchedEquipments = await fetchEquipments();
        setEquipments(fetchedEquipments); // Atualiza os equipamentos no estado
      };

      fetchInitialEquipments(); // Chame a função para buscar os equipamentos
    }
  }, [open]);

  // Função para calcular as estatísticas
  const calculateStats = () => {
    const usageByDay = {};
    const usageOver24h = [];
    const usageOver12h = [];
    const userUsage = {};

    equipments.forEach(equipment => {
      if (equipment.history && equipment.history.length > 0) {
        // Considerar todo o histórico para uso por dia e usuário
        equipment.history.forEach(entry => {
          const date = new Date(entry.date).getHours();
          if (entry.status === 'em uso') {
            usageByDay[date] = (usageByDay[date] || 0) + 1;
            userUsage[entry.nomeCompleto] = (userUsage[entry.nomeCompleto !== 'Nome não encontrado'] || 0) + 1;
          }
        });

        // Considerar apenas o último registro para uso de mais de 12h ou 24h
        const lastEntry = equipment.history[equipment.history.length - 1];
        if (lastEntry.status === 'em uso') {
          const hoursInUse = (new Date() - new Date(lastEntry.date)) / 36e5;
          if (hoursInUse > 24) {
            usageOver24h.push(equipment);
          } else if (hoursInUse > 12) {
            usageOver12h.push(equipment);
          }
        }
      }
    });

    return { usageByDay, usageOver24h, usageOver12h, userUsage };
  };

  const { usageByDay, usageOver24h, usageOver12h, userUsage } = calculateStats();

  const lineChartData = {
    labels: Object.keys(usageByDay),
    datasets: [
      {
        label: 'Equipamentos em Uso por Dia',
        data: Object.values(usageByDay),
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
    ],
  };

  const barChartData24h = {
    labels: usageOver24h.map(equipment => equipment.code),
    datasets: [
      {
        label: 'Equipamentos em Uso + 24 horas',
        data: usageOver24h.map(() => 1),
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
      },
    ],
  };

  const barChartData12h = {
    labels: usageOver12h.map(equipment => equipment.code),
    datasets: [
      {
        label: 'Equipamentos em Uso + 12 horas',
        data: usageOver12h.map(() => 1),
        backgroundColor: 'rgba(54,162,235,0.2)',
        borderColor: 'rgba(54,162,235,1)',
        borderWidth: 1,
      },
    ],
  };

  const pieChartData = {
    labels: Object.keys(userUsage),
    datasets: [
      {
        label: 'Colaborador que Mais Retira Equipamento',
        data: Object.values(userUsage),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{equipments.length}</DialogTitle>
      <DialogContent>
        {/* Gráfico de Total de Equipamentos em Uso por Dia */}
        <Typography variant="h6">Total de Equipamentos em Uso por Dia</Typography>
        <Line data={lineChartData} />

        {/* Gráfico de Total de Equipamentos em Uso + 24 horas */}
        <Typography variant="h6">Total de Equipamentos em Uso + 24 horas</Typography>
        <Bar data={barChartData24h} />

        {/* Gráfico de Total de Equipamentos em Uso + 12 horas */}
        <Typography variant="h6">Total de Equipamentos em Uso + 12 horas</Typography>
        <Bar data={barChartData12h} />

        {/* Gráfico de Colaborador que Mais Retira Equipamento */}
        <Typography variant="h6">Colaborador que Mais Retira Equipamento</Typography>
        <Pie data={pieChartData} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EquipmentStatsModal;