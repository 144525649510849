import React, { useContext, useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, IconButton, Box, Divider, CssBaseline, Typography, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DvrIcon from '@mui/icons-material/Dvr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import WingletLogo from '../components/WingletLogo';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import EditProfileModal from './sections/users/EditProfileModal';
import useUser from '../hooks/useUser';


const PersistentDrawer = ({ open, onClose, handleLogout}) => {
  const [user, setUser] = useState(null);
  const [openUsers, setOpenUsers] = useState(false);
  const [openSystems, setOpenSystems] = useState(false);
  const [isSuperAdminState, setIsSuperAdminState] = useState(false);
  const userModules = user?.userInfo?.user_modulesallowed?.modules || [];
  const drawerWidth = 310;
  const iconStyles = { fontSize: 24, color: '#00002E' }; // Estilo comum para os ícones
  const navigate = useNavigate() 
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const userBadge = sessionStorage.getItem('user_badge');
  const fetchedUser = useUser(userBadge);



  // Effect to show welcome alert when user info is available
  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
    if (user && user.userInfo) {
      if (user?.userInfo?.user_accesslevel.level === "superAdmin" || user?.userInfo?.user_accesslevel.level === "localAdmin") {
        setIsSuperAdminState(true);
      }
    }
  }, [user, fetchedUser]);

  const handleUsersClick = () => {
    setOpenUsers(!openUsers);
  };

  const handleSystemsClick = () => {
    setOpenSystems(!openSystems);
  };

  const capitalizeFirstLetter = (string) => {
    return string.toUpperCase();
  };

  const handleManageUsersClick = () => {
    navigate('/usersdashboard');
  }
  
  const handleModulesClick = (module) => {
    navigate(`/modules/${module}/dashboard`);
  }

    // Handle click to add an equipment
const handleConfigClick = () => {
  setEditProfileModalOpen(true);
};

  return (
    <Box sx={{ display: 'flex', position: 'inherit' }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', overflow: 'hidden' }}>
        <CssBaseline />
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            onClick={onClose}
            edge="start"
            title='Abrir menu'
            sx={{
              position: 'absolute',
              top: 16,
              left: 16,
              zIndex: 1,
              mr: 2,
            }}
          >
            <MenuIcon sx={iconStyles} />
          </IconButton>
        </Toolbar>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={onClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            },
            overflow: 'hidden',
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
            <WingletLogo width={150} />
          </Toolbar>
          <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
            <List>
              {isSuperAdminState && (
                <>
                  <ListItemButton onClick={handleUsersClick} disabled >
                    <ListItemIcon >
                      <ManageAccountsOutlinedIcon sx={iconStyles} />
                    </ListItemIcon>
                    <ListItemText primary="Gerenciar Usuários" sx={{ color: '#00002E' }}/>
                    {openUsers ? <ExpandLess sx={iconStyles} /> : <ExpandMore sx={iconStyles} />}
                  </ListItemButton>
                  <Collapse in={openUsers} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 9 }}>
                        <ListItemText primary="Lista de usuários" sx={{ color: '#00002E' }} onClick={handleManageUsersClick} />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 9 }}>
                        <ListItemText primary="Cadastrar Usuário" sx={{ color: '#00002E' }} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <Divider />
                </>
              )}
              <ListItemButton onClick={handleSystemsClick}>
                <ListItemIcon>
                  <DvrIcon sx={iconStyles} />
                </ListItemIcon>
                <ListItemText primary="Meus sistemas" sx={{ color: '#00002E' }} />
                {openSystems ? <ExpandLess sx={iconStyles} /> : <ExpandMore sx={iconStyles} />}
              </ListItemButton>
              <Collapse in={openSystems} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {userModules.map((module) => (
                    <ListItemButton key={module} sx={{ pl: 9 }} onClick={() => handleModulesClick(module)}>
                      <ListItemText primary={capitalizeFirstLetter(module)} sx={{ color: '#00002E' }} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
              <Divider />
              <ListItem onClick={handleConfigClick} sx={{cursor:'pointer'}}>
                <ListItemIcon>
                  <Avatar alt="Meu perfil" src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=740&t=st=1728511394~exp=1728511994~hmac=361a0701b28956b980557614e273e93c08c5a90501cfcfb8eea243d714fa1916" sx={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary="Meu perfil" sx={{ color: '#00002E' }} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faHeadset} style={iconStyles} />
                </ListItemIcon>
                <ListItemText primary="Suporte" sx={{ color: '#00002E' }} />
              </ListItem>
              <Divider />
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <CloseIcon sx={iconStyles} />
                </ListItemIcon>
                <ListItemText primary="Sair" sx={{ color: '#00002E' }} />
              </ListItemButton>
            </List>
          </Box>
          <Box sx={{ textAlign: 'center', padding: 2 }}>
            <Typography variant="caption" color='#00002E'>
              powered by WFS | ORBITAL
            </Typography>
          </Box>
        </Drawer>
        <EditProfileModal
                open={editProfileModalOpen}
                onClose={() => setEditProfileModalOpen(false)}
              />
      </Box>
    </Box>
  );
};

export default PersistentDrawer;