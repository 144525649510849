import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
  Alert, 
  Snackbar,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import '../App.css';
import { UserContext } from '../contexts/UserContext';
import useUpdatePassword from '../hooks/useUpdatePassword';
import { useNavigate } from "react-router-dom";

const ChangePasswordModal = ({ open, onClose }) => {
  const { user } = useContext(UserContext);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const { updatePassword, success, error } = useUpdatePassword();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordValidity, setPasswordValidity] = useState({
    length: false,
    uppercase: false,
    number: false,
    symbol: false,
  });

  useEffect(() => {
    if (success) {
      sessionStorage.setItem('successMessage', 'Senha atualizada com sucesso. Faça login novamente.');
      onClose();
      window.location.reload();
    }
  }, [success, onClose, navigate]);

  useEffect(() => {
    alertOpen && setTimeout(() => setAlertOpen(false), 3000);
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === 'newPassword') {
      validatePassword(value);
    }
  };

  const validatePassword = (password) => {
    const validity = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidity(validity);
  };

  const isPasswordValid = () => {
    return Object.values(passwordValidity).every(Boolean);
  };

  const handleSave = () => {
    if (formData.newPassword !== formData.confirmPassword) {
      setAlertOpen(true);
      setAlertMessage('As senhas não coincidem');
      setAlertSeverity('error');
      return;
    }
    if (!isPasswordValid()) {
      setAlertOpen(true);
      setAlertMessage('A senha não atende a todos os critérios');
      setAlertSeverity('error');
      return;
    }
    updatePassword(formData);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ backgroundColor: '#f0f0f0', padding: 2, display: 'flex', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6">{"Olá, " + user?.userInfo?.user_firstname + " " + user?.userInfo?.user_surname}</Typography>
          <Typography variant="body" sx={{color:'#00002e'}}>{"Detectamos que este é seu primeiro acesso. Por favor, altere sua senha!"}</Typography>
          <FormHelperText>
          A senha deve ter pelo menos 8 caracteres, incluindo 1 letra maiúscula, 1 símbolo e 1 número.
        </FormHelperText>
        </Box>
      </Box>
      <DialogContent>
        <TextField
          label="Senha nova"
          name="newPassword"
          type="password"
          fullWidth
          value={formData.newPassword}
          onChange={handleInputChange}
          margin="dense"
          error={!isPasswordValid()}
        />
        <TextField
          label="Confirme sua nova senha"
          name="confirmPassword"
          type="password"
          fullWidth
          value={formData.confirmPassword}
          onChange={handleInputChange}
          margin="dense"
          error={formData.confirmPassword !== formData.newPassword}
        />
        <List dense>
          <ListItem>
            <ListItemIcon>{passwordValidity.length ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</ListItemIcon>
            <ListItemText primary="Mínimo de 8 caracteres" />
          </ListItem>
          <ListItem>
            <ListItemIcon>{passwordValidity.uppercase ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</ListItemIcon>
            <ListItemText primary="Pelo menos 1 letra maiúscula" />
          </ListItem>
          <ListItem>
            <ListItemIcon>{passwordValidity.number ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</ListItemIcon>
            <ListItemText primary="Pelo menos 1 número" />
          </ListItem>
          <ListItem>
            <ListItemIcon>{passwordValidity.symbol ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</ListItemIcon>
            <ListItemText primary="Pelo menos 1 símbolo" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', padding: 2 }}>
        <Button onClick={onClose} variant="contained" sx={{ backgroundColor: '#E0E0E0', color: '#00002E', cursor: 'pointer', borderRadius: '6px' }}>
          Cancelar
        </Button>
        <Button onClick={handleSave} color="success" variant="contained" disabled={!isPasswordValid() || formData.newPassword !== formData.confirmPassword}>
          Confirmar
        </Button>
      </DialogActions>
      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ width: 'inherit', mr: 10, marginBlockStart: -2.5, overflow: 'hidden', display: 'flex', position: 'absolute' }}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          onClick={() => setAlertOpen(false)}
          sx={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ChangePasswordModal;
