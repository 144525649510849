import React from 'react';
import poweredImage from '../assets/images/powered_by.png'; // Adjust the path as needed

const PoweredLabel = ({ width = 200, height = 'auto' }) => (
  <img
    src={poweredImage}
    alt="Powered by WFS | Orbital"
    style={{
      alignSelf: 'center',
      width: width,
      height: height,
      maxWidth: '100%',
      objectFit: 'contain',
      marginBlockStart: '15%'
    }}
  />
);

export default PoweredLabel;